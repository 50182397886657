import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

export const StyledListItem = styled(ListItem)`
  display: flex;
  gap: 12px;
`;

export const StyledListItemNumber = styled('span')(({ theme }) => ({
  display: 'inline-block',
  padding: '8px 16px',
  backgroundColor: theme.palette.grey[100],
  borderRadius: 4,
}));

export const StyledAddButtonWrapper = styled('div')`
  margin-left: 70px;
`;
