import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from './styles';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const { onClick, label, ...restProps } = props;

  return (
    <StyledButton {...restProps} onClick={onClick} color="primary">
      {t(label)}
    </StyledButton>
  );
};
