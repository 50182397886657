import { styled } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';

import { StyledTreeItemProps } from './types';

export const StyledTreeItem = styled(TreeItem, {
  shouldForwardProp: (prop) =>
    prop !== 'isLastElement' && prop !== 'isFirstElement',
})<StyledTreeItemProps>(({ isLastElement, isFirstElement }) => ({
  marginTop: isFirstElement ? 16 : 0,
  '.MuiTreeItem-iconContainer': {
    display: isLastElement ? 'none' : 'flex',
  },
  '.MuiTreeItem-content': {
    padding: '0 8px',
  },
}));
