import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'routes/constants';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { CommentsSidebar } from 'components/comments/comments-sidebar/comments-sidebar';

import { UserModel } from 'models/user.model';

import { headerHeight } from 'constants/index';

import { StyledBoxContainer } from './styles';
import { SurveyLayoutProps } from './types';

export const SurveyLayout = ({
  processTitle,
  sectionTitle,
  children,
}: SurveyLayoutProps) => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth0<UserModel>();

  const [isOpenedCommentsSidebar, setIsOpenedCommentsSidebar] = useState(false);

  const path = user?.app_metadata.isAdmin
    ? `/${Routes.ADMIN}/${Routes.ORGANIZATIONS}/${organizationId}`
    : `/${Routes.ORGANIZATIONS}/${organizationId}`;

  return (
    <Grid
      container
      sx={{ minHeight: `calc(100vh - ${headerHeight + 1}px)` }}
      justifyContent="space-between"
    >
      {!isOpenedCommentsSidebar && <Grid item xs={1} />}

      <Grid item justifyContent="center" xs={9.5} sx={{ px: 4, pb: 4 }}>
        <StyledBoxContainer>
          <IconButton
            color="primary"
            sx={{ mr: 2 }}
            onClick={() => navigate(path)}
          >
            <ArrowBackRoundedIcon />
          </IconButton>

          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              {processTitle}
            </Typography>

            <Typography sx={{ fontSize: 24, fontWeight: 800 }}>
              {sectionTitle}
            </Typography>
          </Box>
        </StyledBoxContainer>

        <>{children}</>
      </Grid>

      <Grid item xs={isOpenedCommentsSidebar ? 2.5 : 0.5}>
        <CommentsSidebar
          isOpenedCommentsSidebar={isOpenedCommentsSidebar}
          onSetIsOpenedCommentsSidebar={setIsOpenedCommentsSidebar}
        />
      </Grid>
    </Grid>
  );
};
