// TODO: uncomment add/remove scores code after MVP)
import { FC, useRef } from 'react';

import { Typography } from '@mui/material';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';

import { KeyboardKeys } from 'constants/index';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { MAX_SCORE_AMOUNT } from './constants';
import { StyledListItem, StyledListItemNumber } from './styles';
import { ScoresListProps } from './types';

export const ScoresList: FC<ScoresListProps> = ({
  scores,
  onAddScore,
  onChangeScoreLabel,
  // onRemoveScore,
}) => {
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();
  const lastScoreRef = useRef<HTMLInputElement | null>(null);
  const addingMoreScoresIsDisabled = scores.length === MAX_SCORE_AMOUNT;
  // const [showIconsForItemOnHover, setShowIconsForItemOnHover] = useState<
  //   number | undefined
  // >(undefined);

  const keyPressHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyboardKeys.ENTER && !addingMoreScoresIsDisabled) {
      onAddScore?.();

      setTimeout(() => {
        if (lastScoreRef.current) {
          lastScoreRef.current.focus();
        }
      }, 0);
    }
  };

  return (
    <div>
      <List>
        {scores.map(({ score, label, id }, index) => {
          return (
            <StyledListItem
              key={index}
              // onMouseEnter={() => setShowIconsForItemOnHover(index)}
              // onMouseLeave={() => setShowIconsForItemOnHover(undefined)}
            >
              <StyledListItemNumber>{score}</StyledListItemNumber>
              {isEnLanguage ? (
                <Typography sx={{ marginBottom: 0 }}>
                  {label[currentLanguage]}
                </Typography>
              ) : (
                <TextField
                  inputRef={scores.length - 1 === index ? lastScoreRef : null}
                  // onFocus={() => setShowIconsForItemOnHover(index)}
                  onKeyPress={keyPressHandler}
                  size="small"
                  variant="outlined"
                  onChange={(event) =>
                    onChangeScoreLabel(event.target.value, id)
                  }
                  value={label[currentLanguage]}
                  placeholder="Score description"
                  sx={{ maxWidth: 500 }}
                  fullWidth
                />
              )}
              {/*{showIconsForItemOnHover === index && (*/}
              {/*  <IconButton onClick={() => onRemoveScore(index)}>*/}
              {/*    <DeleteOutlineIcon*/}
              {/*      sx={{ color: theme.palette.common.black }}*/}
              {/*    />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
            </StyledListItem>
          );
        })}
      </List>
      {/*<StyledAddButtonWrapper>*/}
      {/*  <AddButton*/}
      {/*    isDisabled={addingMoreScoresIsDisabled}*/}
      {/*    onClick={onAddScore}*/}
      {/*    label="Add score"*/}
      {/*  />*/}
      {/*</StyledAddButtonWrapper>*/}
    </div>
  );
};
