import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { reportService } from 'services/report.service';

import { AppContext } from 'context';

import { UseGenerateReport } from './types';

export const useGenerateReport = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ organizationId, processId, language }: UseGenerateReport) =>
      await reportService.getReportUrl(
        auth,
        organizationId,
        processId,
        language,
      ),
    {
      mutationKey: queryKeys.generateReport,
      onSuccess: () => queryClient.invalidateQueries(queryKeys.generateReport),
      onError: (error: AxiosError) => {
        const errorMessage =
          t('errors.report.generate_report', {
            error: (error.response?.data as { message: string })?.message,
          }) || t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
