import { Severity } from 'context/types';

import { Node } from 'models/composition.model';

import { ReorderNodesProps } from './types';

export const removeNodeRecursive = (nodes: Node[], id: string) =>
  nodes.filter((node) => {
    if (node.id === id) {
      return false;
    }

    if (node.children) {
      node.children = removeNodeRecursive(node.children, id);
    }

    return true;
  });

export const reorderNodes = ({
  nodes,
  startIndex,
  endIndex,
  t,
  showNotification,
}: ReorderNodesProps) => {
  if (
    nodes.length === 0 ||
    !Number.isInteger(startIndex) ||
    !Number.isInteger(endIndex) ||
    startIndex < 0 ||
    startIndex >= nodes.length ||
    endIndex < 0 ||
    endIndex >= nodes.length
  ) {
    showNotification({
      isShowingNotification: true,
      type: Severity.Error,
      message: t('errors.unknown_error_occurred'),
    });
  }

  const result = [...nodes];

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};
