import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { riskMatrixService } from 'services/risk-matrix.service';

import { AppContext } from 'context';

import { UseUpdateRiskMatrixData } from './types';

export const useUpdateRiskMatrixData = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({
      data,
      organizationId,
      processId,
      templateId,
    }: UseUpdateRiskMatrixData) =>
      await riskMatrixService.updateRiskMatrixData(
        auth,
        organizationId,
        processId,
        templateId,
        data,
      ),
    {
      mutationKey: queryKeys.updateRiskMatrixData,
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries({
            // TODO: invalidate getRiskMatrixData with specific org and process IDs
            queryKey: [
              queryKeys.getRiskMatrixData,
              data.organizationId,
              data.processId,
              data.templateId,
            ],
          });
        }
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
