import { ReactNode, createContext, useState } from 'react';

import { DEFAULT_AUTO_HIDE_DURATION } from './constants';
import { ContextType, NotificationState, Severity } from './types';

const emptyFunction = () => {
  // do nothing
};

const initialContext: ContextType = {
  userResponseId: {
    userResponseId: undefined,
    setUserResponseId: emptyFunction,
  },
  notification: {
    type: Severity.Error,
    message: '',
    subtitle: undefined,
    hideDuration: undefined,
    isShowingNotification: false,
    showNotification: emptyFunction,
    closeNotification: emptyFunction,
  },
};

const initialNotificationState: NotificationState = {
  isShowingNotification: false,
  message: '',
  subtitle: undefined,
  hideDuration: undefined,
  type: Severity.Error,
};

export const AppContext = createContext(initialContext);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [userResponseId, setUserResponseId] = useState<string | undefined>(
    undefined,
  );
  const [notificationState, setNotificationState] = useState(
    initialNotificationState,
  );

  const showNotification = (showNotificationProps: NotificationState) => {
    const { isShowingNotification, message, subtitle, hideDuration, type } =
      showNotificationProps;

    return setNotificationState({
      isShowingNotification,
      message,
      subtitle,
      hideDuration:
        hideDuration === 'disabled'
          ? undefined
          : hideDuration ?? DEFAULT_AUTO_HIDE_DURATION,
      type,
    });
  };

  const closeNotification = () =>
    setNotificationState((prevState) => ({
      ...prevState,
      isShowingNotification: false,
    }));

  const { type, isShowingNotification, message, hideDuration, subtitle } =
    notificationState;

  return (
    <AppContext.Provider
      value={{
        userResponseId: {
          userResponseId,
          setUserResponseId,
        },
        notification: {
          type,
          isShowingNotification,
          message,
          hideDuration,
          subtitle,
          showNotification,
          closeNotification,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
