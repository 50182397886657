import { FieldValues } from 'react-hook-form';

import { NOT_ASSESSED_OVERALL_RISK_VALUE } from 'components/overall-risk-template/constants';

import { RiskScores } from 'models/risk-score.model';

import { ReactComponent as FileCheckIcon } from 'assets/file-check-icon.svg';

import { DEFAULT_ICON, DEFAULT_ICON_BG_COLOR } from './constants';
import { RiskType } from './types';

export const getRiskScore = (formValues: FieldValues, sectionId: string) => {
  const overallRisk =
    formValues?.section2?.overallRisk ?? NOT_ASSESSED_OVERALL_RISK_VALUE;
  const newOverallRisk =
    formValues?.section3?.newOverallRisk ?? NOT_ASSESSED_OVERALL_RISK_VALUE;

  const risk: RiskType = {
    section2: overallRisk,
    section3: newOverallRisk,
  };

  return risk[sectionId] ?? NOT_ASSESSED_OVERALL_RISK_VALUE;
};

export const getIconWrapperBgColor = (
  riskScore: string | number,
  riskScores?: RiskScores,
) => {
  if (riskScore === NOT_ASSESSED_OVERALL_RISK_VALUE) {
    return DEFAULT_ICON_BG_COLOR;
  }

  return riskScores?.[riskScore]?.color ?? DEFAULT_ICON_BG_COLOR;
};

export const renderIcon = (
  riskScore: string | number,
  isSectionCompleted?: boolean,
) => {
  if (isSectionCompleted) {
    return <FileCheckIcon />;
  }

  if (riskScore === NOT_ASSESSED_OVERALL_RISK_VALUE) {
    return DEFAULT_ICON;
  }

  return riskScore;
};
