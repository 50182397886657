import { Box, FormControl, styled } from '@mui/material';

import { theme } from 'configs/theme';

import { StyledFormControlProps } from './types';

export const StyledDropdownScoreWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  fontWeight: 700,
  borderRadius: 4,
  minWidth: 32,
  height: 32,
  marginRight: 16,
});

export const StyledFormControl = styled(FormControl)<StyledFormControlProps>(
  ({ width }) => ({
    width: width ?? '100%',
  }),
);
