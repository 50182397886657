import { enUS, uk } from 'date-fns/locale';

import { DateFormats, DateLocales } from './types';

export const DATE_LOCALES: DateLocales = { ua: uk, en: enUS };

export const DATE_FORMATS: DateFormats = {
  ua: 'd MMMM, HH:mm',
  en: 'MMM d, HH:mm',
};
