import { memo } from 'react';

import { Box, Typography } from '@mui/material';

import { DoubleCircleComponent } from 'components/double-circle-component/double-circle-component';

import { TreeItemLabelProps } from './types';

export const TreeItemLabel = memo(
  ({
    title,
    isFirstElement,
    index,
    overallRiskColor,
    newOverallRiskColor,
  }: TreeItemLabelProps) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: isFirstElement ? 800 : 'normal',
          mr: 0.5,
        }}
      >
        {isFirstElement && `${index}.`} {title}
      </Typography>

      <DoubleCircleComponent
        overallRiskColor={overallRiskColor}
        newOverallRiskColor={newOverallRiskColor}
      />
    </Box>
  ),
);

TreeItemLabel.displayName = 'TreeItemLabel';
