import { useEffect } from 'react';

type QueryParam = string | number | boolean;

const convertToString = (queryParam?: QueryParam) => {
  if (queryParam === undefined || queryParam === '') {
    return null;
  }

  if (typeof queryParam === 'string') {
    return queryParam;
  } else {
    return String(queryParam);
  }
};

export const useSetQueryParam = (
  paramName: string,
  queryParam?: QueryParam | QueryParam[],
) => {
  useEffect(() => {
    const url = new URL(window.location.href);

    url.searchParams.delete(paramName);

    if (Array.isArray(queryParam)) {
      const param = queryParam.join(',');

      url.searchParams.append(paramName, param);
    } else {
      const param = convertToString(queryParam);

      if (param) {
        url.searchParams.set(paramName, param);
      }
    }

    url.searchParams.sort();
    window.history.replaceState(null, '', url.href);
  }, [paramName, queryParam]);
};

export const useGetQueryParam = () =>
  new URLSearchParams(window.location.search);
