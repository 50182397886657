import { useGetOrganizations } from 'hooks/queries/organizations/organizations';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { OrganizationInfo } from 'components/organization-info/organization-info';
import { PageLoader } from 'components/page-loader/page-loader';

export const AdminPage = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetOrganizations();

  if (isError) {
    return <Box sx={{ py: 4, px: 8 }}>{t('load_organizations_failure')}</Box>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        py: 4,
        px: 8,
      }}
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <Button
            component={Link}
            to="new"
            sx={{ marginLeft: 'auto' }}
            startIcon={<AddIcon />}
          >
            {t('create_organization')}
          </Button>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flexStart"
            spacing={2}
            sx={{ width: '400px', maxWidth: '80%', margin: '0 auto' }}
          >
            {data?.map((org) => (
              <OrganizationInfo key={org._id} organization={org} withLink />
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};
