import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Auth0ContextInterface } from '@auth0/auth0-react';

import { useMutation } from 'react-query';

import { UserModel } from 'models/user.model';

import { organizationService } from 'services/organization.service';

import { AppContext } from 'context';

import {
  CreateOrganizationOptions,
  DeleteOrganizationOptions,
  UpdateOrganizationOptions,
} from './types';

export const useCreateOrganization = (
  auth: Auth0ContextInterface<UserModel>,
) => {
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ({ data }: CreateOrganizationOptions) =>
      organizationService.createOrganization(auth, data),
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

export const useUpdateOrganization = (
  auth: Auth0ContextInterface<UserModel>,
) => {
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ({ data, organizationId }: UpdateOrganizationOptions) =>
      organizationService.updateOrganization(auth, organizationId, data),
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

export const useDeleteOrganization = (
  auth: Auth0ContextInterface<UserModel>,
) => {
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ({ id }: DeleteOrganizationOptions) =>
      organizationService.deleteOrganization(auth, id),
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};
