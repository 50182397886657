import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { CssBaseline } from '@mui/material';

import 'configs/i18n/config';

import { ContextProvider } from 'context';

import './index.css';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <ContextProvider>
      <CssBaseline />
      <App />
    </ContextProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
