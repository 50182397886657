import { ChangeEvent } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { FormControlLabel, TextField, Typography } from '@mui/material';

import { emptyString } from 'constants/index';

import { formatValueToCurrency } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { DEFAULT_CURRENCY_VALUE } from './constants';
import { CurrencyTemplateComponentProps } from './types';

export const CurrencyTemplateComponent = ({
  placeholder,
  label,
  fieldName,
  inputProps,
  styles,
  width,
  onUpdateTemplate,
}: CurrencyTemplateComponentProps) => {
  const { isEnLanguage } = useIsEnLanguage();
  const { control } = useFormContext();

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    let value: string | number = event.target.value;

    if (value === emptyString) {
      field.onChange(DEFAULT_CURRENCY_VALUE);
      return;
    }

    const numericValue = parseFloat(value.replace(/[,./]/g, emptyString));

    if (!isNaN(numericValue)) {
      value = numericValue;
    }

    field.onChange(value);
  };

  return (
    <Controller
      key={fieldName}
      name={fieldName}
      control={control}
      render={({ field, fieldState: { error, isDirty } }) => (
        <FormControlLabel
          sx={{
            alignItems: 'flex-start',
            mx: 'unset',
            width: width ?? '100%',
            ...styles,
          }}
          control={
            !isEnLanguage ? (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                size="small"
                placeholder={placeholder}
                type="text"
                inputProps={inputProps}
                fullWidth
                onChange={(event) => handleInputChange(event, field)}
                value={formatValueToCurrency(field.value)}
                onBlur={() => isDirty && onUpdateTemplate?.()}
              />
            ) : (
              <Typography sx={{ marginBottom: 0 }}>{field.value}</Typography>
            )
          }
          label={
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
              {label}
            </Typography>
          }
          labelPlacement="top"
        />
      )}
    />
  );
};
