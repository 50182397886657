import { AppBar, Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledAppBar = styled(AppBar)({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  color: theme.palette.common.black,
});

export const StyledGradientLine = styled(Box)({
  height: 1,
  background:
    'linear-gradient(90deg, rgba(255, 199, 0, 1), rgba(0, 103, 255, 1))',
});
