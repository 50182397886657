import { Alert, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledAlert = styled(Alert)({
  maxWidth: 500,
  alignItems: 'center',
  borderRadius: '8px',
  boxShadow: theme.shadow.medium,
  backgroundColor: theme.palette.common.white,
});
