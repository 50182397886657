import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

export const colorTable = {
  16: 'rgba(255, 127, 117, 0.45)',
  12: 'rgba(255, 149, 87, 0.45)',
  9: 'rgba(255, 171, 57, 0.45)',
  8: 'rgba(255, 171, 57, 0.45)',
  6: 'rgba(174, 206, 59, 0.45)',
  4: 'rgba(174, 206, 59, 0.45)',
  3: 'rgba(7, 172, 117, 0.45)',
  2: 'rgba(7, 172, 117, 0.45)',
  1: 'rgba(7, 172, 117, 0.45)',
};

export const colorTableWithoutOpacity = {
  16: 'rgba(255, 127, 117)',
  12: 'rgba(255, 149, 87)',
  9: 'rgba(255, 171, 57)',
  8: 'rgba(255, 171, 57)',
  6: 'rgba(174, 206, 59)',
  4: 'rgba(174, 206, 59)',
  3: 'rgba(7, 172, 117)',
  2: 'rgba(7, 172, 117)',
  1: 'rgba(7, 172, 117)',
};

export const StyledTable = styled(Table)(() => ({
  '&.MuiTable-root': {
    maxWidth: 984,
    tableLayout: 'fixed',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    padding: 20,
    border: `1px solid ${theme.palette.common.white}`,
    '&:first-child': {
      padding: 0,
      border: `1px solid ${theme.palette.grey[300]}`,
      '& div': {
        display: 'flex',
        '& span': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          padding: '24px 10px',
          maxWidth: 192,
          textAlign: 'center',
          '&:first-child': {
            padding: '24px 0',
            minWidth: 32,
            textAlign: 'center',
            backgroundColor: theme.palette.grey[100],
          },
        },
      },
    },
  },
}));

export const StyledTableCell = styled(TableCell)<{ value?: number }>(
  ({ theme, value }) => ({
    [`&.${tableCellClasses.head}`]: {
      padding: 0,
      border: `1px solid ${theme.palette.grey[300]}`,
      '& span': {
        display: 'block',
        textAlign: 'center',
        padding: '18px 0',
        minHeight: 60,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:first-child': {
          minHeight: 'unset',
          fontWeight: 600,
          padding: '9px 0',
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    [`&.${tableCellClasses.body}`]: {
      // @ts-expect-error Custom dynamic styling based on `value` prop not recognized by TS
      backgroundColor: value ? colorTable[value] : 'unset',
      textAlign: 'center',
    },
  }),
);

export const StyledLegend = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '12px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '8px',
  padding: '16px',
}));

export const StyledLegendItem = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.common.white,
  padding: '4px 8px',
  columnGap: '7px',
}));

export const StyledLegendItemSquare = styled('div')(() => ({
  width: 16,
  height: 16,
  borderRadius: 4,
}));

export const StyledLegendItemLabel = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16px',
  color: theme.palette.common.black,
}));
