import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from '@mui/material';

import { languageLocaleStorageKey } from 'constants/index';

import { LANGUAGES } from './constants';
import { Language } from './types';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLang = localStorage.getItem(languageLocaleStorageKey);
  const [language, setLanguage] = useState(
    currentLang
      ? currentLang
      : navigator.language.includes(Language.EN)
        ? Language.EN
        : Language.UA,
  );

  const changeLanguageHandler = (lang: Language) => {
    const lowerCaseLang = lang.toLowerCase();

    i18n.changeLanguage(lowerCaseLang);
    localStorage.setItem(languageLocaleStorageKey, lowerCaseLang);
    setLanguage(lang);
  };

  return (
    <Tabs
      value={language}
      onChange={(_, newValue) => changeLanguageHandler(newValue)}
    >
      {LANGUAGES.map((language) => (
        <Tab
          key={language}
          label={language.toUpperCase()}
          value={language}
          sx={{ minWidth: 40, p: 0, fontWeight: 800, color: 'grey.400' }}
        />
      ))}
    </Tabs>
  );
};
