// TODO: uncomment add/remove scores code after MVP)
import { useUpdateRiskMatrixData } from 'hooks/mutations/risk-matrix/risk-matrix';
import { UseUpdateRiskMatrixData } from 'hooks/mutations/risk-matrix/types';
import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { UseMutateAsyncFunction } from 'react-query';

import { DescriptionBlock } from 'components/description-block/description-block';
import { Matrix } from 'components/matrix/matrix';
import { ScoresList } from 'components/scores-list';

import {
  RiskMatrixUpdateResponse,
  ScoreListItem,
} from 'models/risk-matrix.model';
import { TemplateTypes } from 'models/template.model';

import { useGetTemplateId } from 'hooks/hooks/useGetTemplateId';
import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { RiskMatrixBlock } from './styles';
import { RiskMatrixPanelProps } from './types';

export const RiskMatrixPanel: FC<RiskMatrixPanelProps> = ({
  effectScoresValues,
  probabilityScoresValues,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { processId, organizationId, stepId } = useParams();

  const [effectScores, setEffectScores] = useState(effectScoresValues);
  const [mutatedElementId, setMutatedElementId] = useState<string | null>(null);
  const [probabilityScores, setProbabilityScores] = useState(
    probabilityScoresValues,
  );

  const { mutateAsync: updateRiskMatrixData } = useUpdateRiskMatrixData();
  const templateId = useGetTemplateId(
    TemplateTypes.RISK_MATRIX,
    organizationId,
    processId,
    stepId,
  );

  const ref =
    useRef<
      UseMutateAsyncFunction<
        RiskMatrixUpdateResponse | undefined,
        unknown,
        UseUpdateRiskMatrixData,
        unknown
      >
    >();

  const updateRiskMatrixDataDebounced = useMemo(() => {
    const callback = (
      effectScores: ScoreListItem[],
      probabilityScores: ScoreListItem[],
      mutatedElementId: string | null,
    ) =>
      ref.current?.({
        data: {
          response: { effectScores, probabilityScores },
          mutatedElementId: mutatedElementId,
          // TODO: create ENUM for templateName
          templateName: 'Risk Matrix',
        },
        organizationId,
        processId,
        templateId,
      });

    return debounce(callback, 1000);
  }, [organizationId, processId, templateId]);

  useEffect(() => {
    ref.current = updateRiskMatrixData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateRiskMatrixDataDebounced(
      effectScores,
      probabilityScores,
      mutatedElementId,
    );
  }, [
    effectScores,
    probabilityScores,
    updateRiskMatrixDataDebounced,
    mutatedElementId,
  ]);

  // const addEffectScoreHandler = () => {
  //   setEffectScores([
  //     ...effectScores,
  //     { score: effectScores.length, label: '' },
  //   ]);
  // };

  const changeEffectScoreLabelHandler = (
    value: string,
    idOfUpdatedScore: string,
  ) => {
    setMutatedElementId(idOfUpdatedScore);
    setEffectScores(
      effectScores.map((scoreItem) =>
        scoreItem.id === idOfUpdatedScore
          ? {
              ...scoreItem,
              label: { ...scoreItem.label, [currentLanguage]: value },
            }
          : scoreItem,
      ),
    );
  };

  // const removeEffectScoreHandler = (indexForDeletion: number) => {
  //   setEffectScores(
  //     effectScores
  //       .filter((_, index) => index !== indexForDeletion)
  //       .map((scoreItem, index) => ({ ...scoreItem, score: index + 1 })),
  //   );
  // };

  // const addProbabilityScoreHandler = () => {
  //   setProbabilityScores([
  //     ...probabilityScores,
  //     { score: probabilityScores.length, label: '' },
  //   ]);
  // };

  const changeProbabilityScoreLabelHandler = (
    value: string,
    idOfUpdatedScore: string,
  ) => {
    setMutatedElementId(idOfUpdatedScore);
    setProbabilityScores(
      probabilityScores.map((scoreItem) =>
        scoreItem.id === idOfUpdatedScore
          ? {
              ...scoreItem,
              label: { ...scoreItem.label, [currentLanguage]: value },
            }
          : scoreItem,
      ),
    );
  };

  // const removeProbabilityScoreHandler = (indexForDeletion: number) => {
  //   setProbabilityScores(
  //     probabilityScores
  //       .filter((_, index) => index !== indexForDeletion)
  //       .map((scoreItem, index) => ({ ...scoreItem, score: index + 1 })),
  //   );
  // };

  useEffect(() => {
    setEffectScores(effectScoresValues);
    setProbabilityScores(probabilityScoresValues);
  }, [effectScoresValues, probabilityScoresValues]);

  return (
    <>
      <Alert severity="info">
        <AlertTitle>{t('risk_definitions.info_alert_title')}</AlertTitle>
        {t('risk_definitions.info_alert_description')}
      </Alert>
      <RiskMatrixBlock>
        <DescriptionBlock
          title={t('risk_definitions.effect_scores.title')}
          description={t('risk_definitions.effect_scores.description')}
        />
        <ScoresList
          scores={effectScores}
          // onAddScore={addEffectScoreHandler}
          onChangeScoreLabel={changeEffectScoreLabelHandler}
          // onRemoveScore={removeEffectScoreHandler}
        />
      </RiskMatrixBlock>
      <RiskMatrixBlock>
        <DescriptionBlock
          title={t('risk_definitions.probability_scores.title')}
          description={t('risk_definitions.probability_scores.description')}
        />
        <ScoresList
          scores={probabilityScores}
          // onAddScore={addProbabilityScoreHandler}
          onChangeScoreLabel={changeProbabilityScoreLabelHandler}
          // onRemoveScore={removeProbabilityScoreHandler}
        />
      </RiskMatrixBlock>
      <RiskMatrixBlock>
        <DescriptionBlock
          title={t('risk_definitions.title')}
          description={t('risk_definitions.description')}
        />
        {(effectScores.length > 0 || probabilityScores.length > 0) && (
          <Matrix
            cols={[...probabilityScores].reverse()}
            rows={[...effectScores].reverse()}
          />
        )}
      </RiskMatrixBlock>
    </>
  );
};
