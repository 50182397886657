import { useUpdateCompositionData } from 'hooks/mutations/composition/composition';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box } from '@mui/material';

import { Button } from 'components/button/button';
import { ConfirmationDialog } from 'components/confirmation-dialog/confirmation-dialog';
import { DropComponent } from 'components/drop-component/drop-component';
import { NOT_ASSESSED_OVERALL_RISK_VALUE } from 'components/overall-risk-template/constants';

import { Node } from 'models/composition.model';

import { AppContext } from 'context';

import { findNodeById } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { TreeNode } from '../tree-node/tree-node';
import { TreeProps } from './types';
import { removeNodeRecursive, reorderNodes } from './utils';

export const Tree = memo(
  ({ data, organizationId, processId, templateId }: TreeProps) => {
    const initializedCopy = (nodes: Node[], parentChildrenCount = 0): Node[] =>
      nodes.map(
        ({
          children,
          title,
          childrenCount,
          id = '',
          overallRisk,
          newOverallRisk,
          isCollapsed,
        }) => ({
          children:
            children && initializedCopy(children, parentChildrenCount + 1),
          changeTitle: (newTitle: string) => changeTitle(id, newTitle),
          removeNode: () => openConfirmationDialog(id),
          toggleCollapse: () => toggleCollapse(id),
          addChild: () => addChild(id, parentChildrenCount),
          id,
          title,
          childrenCount,
          overallRisk,
          newOverallRisk,
          isCollapsed,
        }),
      );

    const {
      notification: { showNotification },
    } = useContext(AppContext);
    const { isEnLanguage, currentLanguage } = useIsEnLanguage();
    const { t } = useTranslation();

    const idOfElementWithMutatedTitleRef = useRef<string | undefined>();
    const nodesAreModifiedRef = useRef<boolean>(false);
    const [nodes, setNodes] = useState<Node[]>(initializedCopy(data));
    const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);

    const { mutateAsync: updateCompositionData } = useUpdateCompositionData();

    useEffect(() => {
      const updateNodes = async () => {
        if (nodesAreModifiedRef.current) {
          await updateCompositionData({
            data: {
              response: nodes,
              mutatedElementId: idOfElementWithMutatedTitleRef.current,
              templateName: 'Decomposition',
            },
            organizationId,
            processId,
            templateId,
            onSettledCb: () => {
              idOfElementWithMutatedTitleRef.current = undefined;
              nodesAreModifiedRef.current = false;
            },
          });
        }
      };

      updateNodes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, processId, organizationId, templateId]);

    // to show updated value on the tab with english
    useEffect(() => {
      setNodes(initializedCopy(data));
    }, [data]);

    const updateNode = (id: string, updateCallback: (node: Node) => void) =>
      setNodes((prevNodes) => {
        const changingNode = findNodeById(prevNodes, id);

        if (changingNode) {
          updateCallback(changingNode);
        }
        return [...prevNodes];
      });

    const changeTitle = (id: string, newTitle: string) => {
      idOfElementWithMutatedTitleRef.current = id;
      nodesAreModifiedRef.current = true;
      updateNode(id, (node) => {
        node.title[currentLanguage] = newTitle;
      });
    };

    const toggleCollapse = (id: string) => {
      idOfElementWithMutatedTitleRef.current = undefined;
      nodesAreModifiedRef.current = true;
      updateNode(id, (node) => (node.isCollapsed = !node.isCollapsed));
    };

    const openConfirmationDialog = (id: string) => {
      setSelectedNodeId(id);
    };

    const handleConfirmationDialogClose = () => {
      nodesAreModifiedRef.current = false;
      setSelectedNodeId(null);
    };
    const handleDeleteConfirmation = () => {
      nodesAreModifiedRef.current = true;
      if (selectedNodeId) {
        setNodes((currentNodes) =>
          removeNodeRecursive(currentNodes, selectedNodeId),
        );
        setSelectedNodeId(null);
      }
    };

    const addChild = (parentId: string, parentChildrenCount: number) => {
      if (parentChildrenCount >= 3) {
        return;
      }

      return setNodes((prevNodes) => {
        const parentNode = findNodeById(prevNodes, parentId);

        if (!parentNode) {
          return prevNodes;
        }

        const id = uuidv4();
        const newNode = {
          children: [],
          changeTitle: (newTitle: string) => changeTitle(id, newTitle),
          removeNode: () => openConfirmationDialog(id),
          toggleCollapse: () => toggleCollapse(id),
          addChild: () => addChild(id, parentChildrenCount + 1),
          id,
          title: {
            en: '',
            ua: '',
          },
          childrenCount: parentChildrenCount,
          overallRisk: NOT_ASSESSED_OVERALL_RISK_VALUE,
          newOverallRisk: NOT_ASSESSED_OVERALL_RISK_VALUE,
          isCollapsed: true,
        };

        if (!parentNode.children) {
          parentNode.children = [];
        }

        parentNode.isCollapsed = false;
        parentNode.children.push(newNode);

        return [...prevNodes];
      });
    };

    const addRootElement = () => {
      const id = uuidv4();
      const newNode = {
        children: [],
        changeTitle: (newTitle: string) => changeTitle(id, newTitle),
        removeNode: () => openConfirmationDialog(id),
        toggleCollapse: () => toggleCollapse(id),
        addChild: () => addChild(id, 0),
        id,
        title: {
          en: '',
          ua: '',
        },
        overallRisk: NOT_ASSESSED_OVERALL_RISK_VALUE,
        newOverallRisk: NOT_ASSESSED_OVERALL_RISK_VALUE,
        isCollapsed: true,
      };

      setNodes((prevNodes) => [...prevNodes, newNode]);
    };

    const onDragEnd = (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      nodesAreModifiedRef.current = true;
      setNodes((prevNodes) => {
        const newNodes = reorderNodes({
          nodes: prevNodes,
          startIndex: source.index,
          endIndex: destination.index,
          t,
          showNotification,
        });

        return newNodes;
      });
    };

    return (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <DropComponent droppableId="droppable-tree">
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {nodes.map((node, index) => (
                  <Draggable
                    key={node.id}
                    draggableId={node.id || ''}
                    index={index}
                  >
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.draggableProps}>
                        <TreeNode
                          key={node.id}
                          ref={nodesAreModifiedRef}
                          draggableProvided={provided}
                          node={node}
                          isFirstNode={index === 0}
                          setNodes={setNodes}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
              </Box>
            )}
          </DropComponent>
        </DragDropContext>

        {!isEnLanguage && (
          <Box sx={{ ml: 7 }}>
            <Button
              onClick={addRootElement}
              label="decomposition.add_system"
              startIcon={<AddRoundedIcon />}
            />
          </Box>
        )}

        <ConfirmationDialog
          title="decomposition.delete_node_confirmation"
          open={selectedNodeId !== null}
          onClose={handleConfirmationDialogClose}
          onSubmit={handleDeleteConfirmation}
        />
      </>
    );
  },
);

Tree.displayName = 'Tree';
