import { FC } from 'react';

import {
  StyledDoubleCircleWrapper,
  StyledLeftCircle,
  StyledRightCircle,
} from './styles';
import { DoubleCircleComponentProps } from './types';

export const DoubleCircleComponent: FC<DoubleCircleComponentProps> = ({
  overallRiskColor,
  newOverallRiskColor,
}) => (
  <StyledDoubleCircleWrapper>
    <StyledLeftCircle backgroundColor={overallRiskColor} />
    <StyledRightCircle backgroundColor={newOverallRiskColor} />
  </StyledDoubleCircleWrapper>
);
