import { ComponentType } from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { PageLoader } from '../page-loader/page-loader';

interface AuthenticationGuardProps {
  component: ComponentType<object>;
}

export const AuthenticationGuard = ({
  component,
}: AuthenticationGuardProps) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoader />,
  });

  return <Component />;
};
