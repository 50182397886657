export enum Routes {
  ADMIN = 'admin',
  ORGANIZATIONS = 'organizations',
  PROCESSES = 'processes',
  STEPS = 'steps',
  GENERAL_INFORMATION_SURVEY = 'general-information-survey',
  DETAILED_ASSESSMENT_SURVEY = 'detailed-assessment-survey',
}

export enum DetailedAssessmentRoutes {
  DECOMPOSITION = 'decomposition',
  RISK_MATRIX = 'risk-matrix',
  POTENTIAL_FAILURES = 'failures-and-remedy',
  RECOMMENDED_PRIORITY = 'recommended-priority',
}
