import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .transform((value) => (typeof value === 'string' ? value.trim() : value))
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(100, 'Name must not exceed 100 characters'),
  address: yup
    .string()
    .required('Address is required')
    .max(200, 'Address must not exceed 200 characters'),
  edrpouCode: yup
    .number()
    .required('EDRPOU code is required')
    .nullable()
    .integer('EDRPOU code must be an integer')
    .test('len', 'EDRPOU code must be exactly 8 digits', (val) =>
      val ? val.toString().length === 8 : false,
    ),
  phones: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        value: yup.string().required('Phone is required'),
      }),
    )
    .min(1, 'At least one phone is required'),
});
