import { useMemo } from 'react';

import { NOT_ASSESSED_OVERALL_RISK_VALUE } from 'components/overall-risk-template/constants';

import {
  RecommendedPriorityDataSortBy,
  RecommendedPriorityItem,
} from 'models/recommended-priority.model';

export const useFilterAndSortRecommendedPriorityData = (
  data: RecommendedPriorityItem[],
  sortBy: RecommendedPriorityDataSortBy,
  filterBy: ['all'] | number[],
) => {
  return useMemo(() => {
    let filteredAndSortedArray = [...data];

    if (!(filterBy as ['all']).includes('all')) {
      filteredAndSortedArray = filteredAndSortedArray.filter((elem) =>
        (filterBy as number[]).includes(elem.nestedLevel),
      );
    }

    switch (sortBy) {
      case RecommendedPriorityDataSortBy.DECOMPOSITION:
        return filteredAndSortedArray.sort(
          (a, b) => a.nestedLevel - b.nestedLevel,
        );
      case RecommendedPriorityDataSortBy.COST:
        return filteredAndSortedArray.sort((a, b) => b.amount - a.amount);
      case RecommendedPriorityDataSortBy.PRIORITY:
        return filteredAndSortedArray.sort((a, b) => {
          if (
            a.overallRiskLevelDifference === NOT_ASSESSED_OVERALL_RISK_VALUE &&
            b.overallRiskLevelDifference !== NOT_ASSESSED_OVERALL_RISK_VALUE
          ) {
            return 1;
          } else if (
            a.overallRiskLevelDifference !== NOT_ASSESSED_OVERALL_RISK_VALUE &&
            b.overallRiskLevelDifference === NOT_ASSESSED_OVERALL_RISK_VALUE
          ) {
            return -1;
          } else if (
            b.overallRiskLevelDifference !== a.overallRiskLevelDifference
          ) {
            // @ts-expect-error: Type 'string | number' is not assignable to type 'number'.
            // The subtraction operator cannot be applied to strings, hence expecting a potential type error.
            return b.overallRiskLevelDifference - a.overallRiskLevelDifference;
          } else {
            // @ts-expect-error: Type 'string | number' is not assignable to type 'number'.
            // Similar to above, the risk levels may include non-numeric values.
            return b.initialOverallRiskLevel - a.initialOverallRiskLevel;
          }
        });
      default:
        return filteredAndSortedArray;
    }
  }, [data, filterBy, sortBy]);
};
