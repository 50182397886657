import { StyledPaperLayoutWrapper } from './styles';
import { PaperLayoutProps } from './types';

export const PaperLayout = ({
  children,
  borderRadius,
  styles,
}: PaperLayoutProps) => (
  <StyledPaperLayoutWrapper borderRadius={borderRadius} sx={styles}>
    {children}
  </StyledPaperLayoutWrapper>
);
