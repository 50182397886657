import { FC, Fragment, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { generateDefaultValues, getComponentByType } from 'utils';

import { StyledSectionsWrapper } from './styles';
import { PotentialFailureFormProps } from './types';

const PotentialFailureForm: FC<PotentialFailureFormProps> = ({
  sections,
  onFailuresTemplateUpdate,
  riskScores,
  onCollapsibleSection,
  sectionStates,
}) => {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: generateDefaultValues(sections),
  });

  useEffect(() => {
    methods.reset(generateDefaultValues(sections));
  }, [sections, methods]);

  const handleSubmitWithBlur = async (mutatedElementId?: string) => {
    const values = methods.getValues();

    await onFailuresTemplateUpdate(values, mutatedElementId);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <StyledSectionsWrapper>
          {sections.map((section) => (
            <Fragment key={section.id}>
              {getComponentByType({
                element: section,
                sectionId: section.id,
                t,
                riskScores,
                isSectionCollapsed: sectionStates[section.id],
                onCollapsibleSection,
                onUpdateTemplate: handleSubmitWithBlur,
              })}
            </Fragment>
          ))}
        </StyledSectionsWrapper>
      </form>
    </FormProvider>
  );
};

export default PotentialFailureForm;
