import { Grid } from '@mui/material';

import { ProcessItem } from 'components/processes/process-item/process-item';

import { ProcessesListProps } from './types';

export const ProcessesList = ({ processes }: ProcessesListProps) => (
  <Grid container spacing={3}>
    {processes.map((process) => (
      <Grid key={process._id} item xs={12}>
        <ProcessItem process={process} />
      </Grid>
    ))}
  </Grid>
);
