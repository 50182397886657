import { forwardRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Box } from '@mui/material';

import { TreeNode } from '../tree-node/tree-node';
import { ChildrenNodeProps } from './types';

export const ChildrenNode = forwardRef<boolean, ChildrenNodeProps>(
  ({ nodes, isFirstNode, setNodes }, ref) => (
    <Box sx={{ ml: '52px' }}>
      {nodes?.map((node, index) => (
        <Draggable key={node.id} draggableId={node.id!} index={index}>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.draggableProps}>
              <TreeNode
                ref={ref}
                node={node}
                draggableProvided={provided}
                isFirstNode={isFirstNode}
                setNodes={setNodes}
              />
            </Box>
          )}
        </Draggable>
      ))}
    </Box>
  ),
);

ChildrenNode.displayName = 'ChildrenNode';
