import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'context';

import { useGetQueryParam, useSetQueryParam } from './queryParameters';

const QUERY_NAME = 'userResponseId';

export const useUserResponseId = () => {
  const queryParams = useGetQueryParam();
  const {
    userResponseId: { setUserResponseId },
  } = useContext(AppContext);

  const [state, setState] = useState<string | undefined>(undefined);

  useEffect(() => {
    const param = queryParams.get(QUERY_NAME);

    if (param) {
      setState(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserResponseId(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useSetQueryParam(QUERY_NAME, state);

  return { state, setState };
};
