import { InputBaseComponentProps, SxProps, Theme } from '@mui/material';

export enum ComponentTypes {
  SECTION = 'section',
  TEXT = 'text',
  PHONE = 'phone',
  COMMENT = 'comment',
  FILE = 'file',
  MULTIPLE_TEXT = 'multipleText',
  CURRENCY = 'currency',
  OVERALL_RISK = 'overallRisk',
}

export interface Template {
  json: JsonTemplate;
  name: string;
  organizationId: string;
  processId: string;
  templateId: string;
  uiId?: string;
  _id: string;
}

export interface JsonTemplate {
  id?: string;
  title: string;
  processTitle: string;
  sections: SectionTemplate[];
}

export type ComponentTemplate =
  | SectionTemplate
  | TextTemplate
  | PhoneTemplate
  | CommentTemplate
  | FileTemplate
  | MultipleTextTemplate
  | CurrencyTemplate
  | OverallRiskTemplate;

export interface SectionTemplate extends CommonProperties {
  type: ComponentTypes.SECTION;
  stepLabel: string;
  isCollapsed?: boolean;
  overallRisk?: string;
  newOverallRisk?: string;
}

export interface TextTemplate extends CommonProperties {
  type: ComponentTypes.TEXT;
}

export interface PhoneTemplate extends CommonProperties {
  type: ComponentTypes.PHONE;
}

export interface CommentTemplate extends CommonProperties {
  type: ComponentTypes.COMMENT;
}

export interface FileTemplate extends CommonProperties {
  type: ComponentTypes.FILE;
}

export interface OverallRiskTemplate extends CommonProperties {
  type: ComponentTypes.OVERALL_RISK;
  effectScore: Score;
  probabilityScore: Score;
  newOverallRisk?: number;
  overallRisk?: number;
}

export type Score = {
  label: string;
  options: DropdownItemTemplate[];
  styles?: SxProps<Theme>;
  width?: string;
  value?: string;
};

export interface MultipleTextTemplate extends CommonProperties {
  type: ComponentTypes.MULTIPLE_TEXT;
  firstTitle?: string;
  secondTitle?: string;
  firstPlaceholder?: string;
  secondPlaceholder?: string;
}

export interface CurrencyTemplate extends CommonProperties {
  type: ComponentTypes.CURRENCY;
}

export interface DropdownItemTemplate {
  id?: string;
  value: number;
  label: {
    en: string;
    ua: string;
  };
}

export type OverallRiskType = {
  effectScore: string;
  probabilityScore: string;
  newOverallRisk: number;
  overallRisk: number;
};

export interface MultipleTextItemTemplate {
  id?: string;
  field1: { ua: string; en: string };
  field2: { ua: string; en: string };
}

export interface CommonProperties {
  id: string;
  label?: string;
  styles?: SxProps<Theme>;
  placeholder?: string;
  value?: ElementType;
  rows?: number;
  inputProps?: InputBaseComponentProps;
  acceptedTypes?: string;
  multiple?: boolean;
  inputType?: string;
  description?: string;
  items?: MultipleTextItemTemplate[];
  isCollapsible?: boolean;
  isCompleted?: boolean;
  title?: string;
  elements?: ComponentTemplate[];
  width?: string;
}

export enum TemplateTypes {
  DECOMPOSITION = 'decomposition',
  GENERAL_INFORMATION = 'general-information',
  RISK_MATRIX = 'risk-matrix',
  RECOMMENDED_PRIORITY = 'recommended-priority',
}

export type TemplateType = { name: string; _id: TemplateTypes };

export type CompletedSteps = {
  [key: string]: boolean;
};

export interface DefaultValues {
  [sectionId: string]: Element;
}

export type Element = {
  [elementId: string]: ElementType;
};

export type ElementType =
  | string
  | number
  | string[]
  | MultipleTextItemTemplate[]
  | DropdownItemTemplate[]
  | OverallRiskType;

export type SectionStates = Record<string, boolean | undefined>;
