import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, TextField, Typography } from '@mui/material';

import {
  MAX_LENGTH_TEXT_CHARACTERS,
  MIN_LENGTH_CHARACTERS,
} from 'constants/index';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { TextTemplateComponentProps } from './types';

export const TextTemplateComponent = ({
  elementId,
  placeholder,
  label,
  fieldName,
  inputType,
  inputProps,
  styles,
  width,
  onUpdateTemplate,
}: TextTemplateComponentProps) => {
  const { t } = useTranslation();
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();
  const { control } = useFormContext();

  console.log('text-template');
  return (
    <Controller
      key={fieldName}
      name={fieldName}
      control={control}
      render={({ field, fieldState: { error, isDirty } }) => (
        <FormControlLabel
          sx={{
            alignItems: 'flex-start',
            mx: 'unset',
            width: width ?? '100%',
            ...styles,
          }}
          control={
            isEnLanguage ? (
              <Typography sx={{ marginBottom: 0 }}>
                {field?.value && field?.value?.[currentLanguage]
                  ? field?.value?.[currentLanguage]
                  : t('empty_field')}
              </Typography>
            ) : (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                size="small"
                placeholder={placeholder}
                type={inputType}
                inputProps={{
                  ...inputProps,
                  maxLength: MAX_LENGTH_TEXT_CHARACTERS,
                  minLength: MIN_LENGTH_CHARACTERS,
                }}
                fullWidth
                value={field?.value?.[currentLanguage]}
                onChange={(e) => {
                  const updatedValue = {
                    ...field.value,
                    [currentLanguage]: e.target.value,
                  };

                  field.onChange(updatedValue);
                }}
                onBlur={(e) => {
                  const updatedValue = {
                    ...field.value,
                    [currentLanguage]: e.target.value.trim(),
                  };

                  field.onChange(updatedValue);
                  if (isDirty) {
                    onUpdateTemplate?.(elementId);
                  }
                }}
              />
            )
          }
          label={
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
              {label}
            </Typography>
          }
          labelPlacement="top"
        />
      )}
    />
  );
};
