import { FC, KeyboardEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';

import { VisuallyHiddenInput } from 'components/file-upload/styles';

import { ReactComponent as ArrowUpSendIcon } from 'assets/arrow-up-send.svg';
import { ReactComponent as AttachmentIcon } from 'assets/attachment-icon.svg';

import {
  KeyboardKeys,
  MAX_LENGTH_COMMENT_CHARACTERS,
  MIN_LENGTH_CHARACTERS,
} from 'constants/index';

import { ACCEPT_FILES } from './constants';
import {
  ArrowUpSendIconInputAdornment,
  AttachmentIconInputAdornment,
  StyledTextField,
} from './styles';
import { CommentTextFieldProps } from './types';

export const CommentTextField: FC<CommentTextFieldProps> = ({
  isEditingMode,
  isCreatingMode,
  isTextFieldFocused = true,
  commentValue,
  onSetIsTextFieldFocused,
  onFileChange,
  onCommentSend,
  onSetCommentValue,
}) => {
  const { t } = useTranslation();

  const handleSendButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    onCommentSend?.();
    isCreatingMode && onSetCommentValue('');
    onSetIsTextFieldFocused(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyboardKeys.ENTER && !event.shiftKey) {
      event.preventDefault();

      if (commentValue.trim().length > MIN_LENGTH_CHARACTERS) {
        onCommentSend?.();
        isCreatingMode && onSetCommentValue('');
      }
    }
  };

  const endAdornment = (
    <>
      <AttachmentIconInputAdornment
        position="end"
        isEditingMode={isEditingMode}
        isTextFieldFocused={isTextFieldFocused}
      >
        <IconButton component="label" size="small">
          <AttachmentIcon style={{ height: 20, width: 20 }} />

          <VisuallyHiddenInput
            type="file"
            accept={ACCEPT_FILES}
            multiple={true}
            onChange={onFileChange}
          />
        </IconButton>
      </AttachmentIconInputAdornment>

      {!isEditingMode && (
        <ArrowUpSendIconInputAdornment
          position="end"
          isTextFieldFocused={isTextFieldFocused}
        >
          <IconButton
            size="small"
            onClick={handleSendButtonClick}
            disabled={commentValue.length < MIN_LENGTH_CHARACTERS}
          >
            <ArrowUpSendIcon style={{ height: 20, width: 20 }} />
          </IconButton>
        </ArrowUpSendIconInputAdornment>
      )}
    </>
  );

  return (
    <StyledTextField
      placeholder={t('comments.comment_text_input_placeholder')}
      fullWidth
      multiline
      size="small"
      rows={isTextFieldFocused ? 5 : 1}
      InputProps={{ endAdornment }}
      onKeyDown={handleKeyDown}
      value={commentValue}
      onChange={(event) => onSetCommentValue(event.target.value)}
      onClick={() => onSetIsTextFieldFocused(true)}
      inputProps={{
        maxLength: MAX_LENGTH_COMMENT_CHARACTERS,
        minLength: MIN_LENGTH_CHARACTERS,
      }}
      focused={isTextFieldFocused}
      helperText={`${commentValue.length}/${MAX_LENGTH_COMMENT_CHARACTERS}`}
      isTextFieldFocused={isTextFieldFocused}
    />
  );
};
