import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledLastChildrenIconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  borderRadius: 4,
  minWidth: 40,
  height: 40,
  marginRight: 16,
});
