import { useGetOrganizationById } from 'hooks/queries/organizations/organizations';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { DetailedAssessmentRoutes, Routes } from 'routes/constants';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import TableViewIcon from '@mui/icons-material/TableView';
import { Box, Grid } from '@mui/material';

import { PaperLayout } from 'components/paper-layout/paper-layout';
import { SurveyLayout } from 'components/survey-layout/survey-layout';
import { Tabs } from 'components/tabs/tabs';

import { ProcessStepWithIndex } from 'models/process.model';

import { getProcessTitle } from 'utils';

function findStepIdByName(
  steps: ProcessStepWithIndex[],
  match: DetailedAssessmentRoutes,
) {
  const step = steps.find((step) =>
    step.step.name.replace(/ /g, '-').toLowerCase().includes(match),
  )?.step;

  return step?._id;
}

export const DetailedAssessmentPage = () => {
  const { organizationId, processId } = useParams();
  const { data: organization } = useGetOrganizationById(organizationId);
  const { t } = useTranslation();

  const detailedAssessmentSteps = organization?.processes?.find(
    (process) => process._id === processId,
  )?.steps;

  if (!detailedAssessmentSteps) {
    return null;
  }

  const decompositionStepId = findStepIdByName(
    detailedAssessmentSteps,
    DetailedAssessmentRoutes.DECOMPOSITION,
  );

  const riskMatrixStepId = findStepIdByName(
    detailedAssessmentSteps,
    DetailedAssessmentRoutes.RISK_MATRIX,
  );

  const failuresAndRemediesStepId = findStepIdByName(
    detailedAssessmentSteps,
    DetailedAssessmentRoutes.POTENTIAL_FAILURES,
  );

  const recommendedPriorityStepId = findStepIdByName(
    detailedAssessmentSteps,
    DetailedAssessmentRoutes.RECOMMENDED_PRIORITY,
  );

  const getDetailedAssessmentTabs = [
    {
      icon: <AccountTreeIcon />,
      label: t('decomposition.title'),
      value: DetailedAssessmentRoutes.DECOMPOSITION,
      path: `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.PROCESSES}/${processId}/${Routes.STEPS}/${decompositionStepId}/${Routes.DETAILED_ASSESSMENT_SURVEY}/${DetailedAssessmentRoutes.DECOMPOSITION}`,
    },
    {
      icon: <TableViewIcon />,
      label: t('risk_definitions.title'),
      value: DetailedAssessmentRoutes.RISK_MATRIX,
      path: `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.PROCESSES}/${processId}/${Routes.STEPS}/${riskMatrixStepId}/${Routes.DETAILED_ASSESSMENT_SURVEY}/${DetailedAssessmentRoutes.RISK_MATRIX}`,
    },
    {
      icon: <ElectricBoltIcon />,
      label: t('failures_and_remedy.title'),
      value: DetailedAssessmentRoutes.POTENTIAL_FAILURES,
      path: `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.PROCESSES}/${processId}/${Routes.STEPS}/${failuresAndRemediesStepId}/${Routes.DETAILED_ASSESSMENT_SURVEY}/${DetailedAssessmentRoutes.POTENTIAL_FAILURES}`,
    },
    {
      icon: <SortRoundedIcon />,
      label: t('recommended_priority.title'),
      value: DetailedAssessmentRoutes.RECOMMENDED_PRIORITY,
      path: `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.PROCESSES}/${processId}/${Routes.STEPS}/${recommendedPriorityStepId}/${Routes.DETAILED_ASSESSMENT_SURVEY}/${DetailedAssessmentRoutes.RECOMMENDED_PRIORITY}`,
    },
  ];

  const processTitle = getProcessTitle(organization?.processes, processId);

  return (
    <SurveyLayout
      processTitle={t(processTitle)}
      sectionTitle={t('sections.detailed_assessment')}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <PaperLayout borderRadius={4}>
            <Tabs
              tabsData={getDetailedAssessmentTabs}
              defaultValue={DetailedAssessmentRoutes.RISK_MATRIX}
            />

            <Box>
              <Outlet />
            </Box>
          </PaperLayout>
        </Grid>
      </Grid>
    </SurveyLayout>
  );
};
