import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Divider, IconButton, TextField, Typography } from '@mui/material';

import {
  MAX_LENGTH_TEXT_CHARACTERS,
  MIN_LENGTH_CHARACTERS,
} from 'constants/index';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import {
  StyledFieldsWrapper,
  StyledFormControlLabel,
  StyledIndexWrapper,
  StyledMultipleTextItemWrapper,
  StyledTextFieldLabel,
} from './styles';
import { MultipleTextItemProps } from './types';

export const MultipleTextItem = ({
  fieldName,
  index,
  showDivider,
  showDeleteButton,
  element,
  removeField,
  onUpdateTemplate,
}: MultipleTextItemProps) => {
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { firstTitle, secondTitle, firstPlaceholder, secondPlaceholder } =
    element;

  return (
    <>
      <StyledMultipleTextItemWrapper>
        <StyledIndexWrapper>
          <Typography sx={{ fontWeight: 700 }}>{index + 1}</Typography>
        </StyledIndexWrapper>

        <StyledFieldsWrapper>
          <Controller
            name={`${fieldName}[${index}].field1`}
            control={control}
            render={({ field, fieldState: { error, isDirty } }) => {
              return (
                <StyledFormControlLabel
                  control={
                    isEnLanguage ? (
                      <Typography sx={{ marginBottom: 0 }}>
                        {field.value?.[currentLanguage] || t('empty_field')}
                      </Typography>
                    ) : (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        size="small"
                        placeholder={t(firstPlaceholder ?? '')}
                        fullWidth
                        value={field.value?.[currentLanguage]}
                        onChange={(e) => {
                          const updatedValue = {
                            ...field.value,
                            [currentLanguage]: e.target.value,
                          };

                          field.onChange(updatedValue);
                        }}
                        onBlur={(e) => {
                          const updatedValue = {
                            ...field.value,
                            [currentLanguage]: e.target.value.trim(),
                          };

                          field.onChange(updatedValue);
                          if (isDirty) {
                            onUpdateTemplate?.(field.value.id);
                          }
                        }}
                        inputProps={{
                          maxLength: MAX_LENGTH_TEXT_CHARACTERS,
                          minLength: MIN_LENGTH_CHARACTERS,
                          readOnly: isEnLanguage,
                          disabled: isEnLanguage,
                        }}
                      />
                    )
                  }
                  label={
                    <StyledTextFieldLabel>
                      {t(firstTitle ?? '')}
                    </StyledTextFieldLabel>
                  }
                  labelPlacement="top"
                />
              );
            }}
          />

          <Controller
            name={`${fieldName}[${index}].field2`}
            control={control}
            render={({ field, fieldState: { error, isDirty } }) => (
              <StyledFormControlLabel
                control={
                  isEnLanguage ? (
                    <Typography sx={{ marginBottom: 0 }}>
                      {field.value?.[currentLanguage] || t('empty_field')}
                    </Typography>
                  ) : (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      size="small"
                      placeholder={t(secondPlaceholder ?? '')}
                      fullWidth
                      value={field.value?.[currentLanguage]}
                      onChange={(e) => {
                        const updatedValue = {
                          ...field.value,
                          [currentLanguage]: e.target.value,
                        };

                        field.onChange(updatedValue);
                      }}
                      onBlur={(e) => {
                        const updatedValue = {
                          ...field.value,
                          [currentLanguage]: e.target.value.trim(),
                        };

                        field.onChange(updatedValue);
                        if (isDirty) {
                          onUpdateTemplate?.(field.value.id);
                        }
                      }}
                      inputProps={{
                        maxLength: MAX_LENGTH_TEXT_CHARACTERS,
                        minLength: MIN_LENGTH_CHARACTERS,
                        readOnly: isEnLanguage,
                        disabled: isEnLanguage,
                      }}
                    />
                  )
                }
                label={
                  <StyledTextFieldLabel>
                    {t(secondTitle ?? '')}
                  </StyledTextFieldLabel>
                }
                labelPlacement="top"
              />
            )}
          />
        </StyledFieldsWrapper>

        {showDeleteButton && (
          <IconButton
            color="primary"
            onClick={() => removeField(index)}
            sx={{ mt: 3 }}
          >
            <DeleteOutlineRoundedIcon />
          </IconButton>
        )}
      </StyledMultipleTextItemWrapper>

      {showDivider && <Divider />}
    </>
  );
};
