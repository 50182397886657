import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const RecommendedPriorityPanelWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: 32,
  backgroundColor: theme.palette.graymodern[25],
});

export const RecommendedPriorityFiltersWrapper = styled(Box)({
  display: 'flex',
  columnGap: 32,
  marginBottom: 32,
});
