import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateGeneralInformationTemplate } from 'hooks/mutations/general-information-template/general-information-template';
import { useGetGeneralInformationTemplate } from 'hooks/queries/general-information-template/general-information-template';
import { useGetOrganizationById } from 'hooks/queries/organizations/organizations';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'routes/constants';

import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { Button, Grid } from '@mui/material';

import { PageLoader } from 'components/page-loader/page-loader';
import { PaperLayout } from 'components/paper-layout/paper-layout';
import { SurveyLayout } from 'components/survey-layout/survey-layout';
import { VerticalLinearStepper } from 'components/vertical-linear-stepper/vertical-linear-stepper';

import {
  CompletedSteps,
  ComponentTypes,
  DefaultValues,
  TemplateTypes,
} from 'models/template.model';

import { surveyLayoutHeight } from 'constants/index';

import {
  deserializeFormData,
  generateDefaultValues,
  generateValidationSchema,
  getComponentByType,
  getProcessTitle,
} from 'utils';

import { useGetTemplateId } from 'hooks/hooks/useGetTemplateId';
import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';
import { useUserResponseId } from 'hooks/hooks/useUserResponseId';

import { StyledSectionsWrapper } from './styles';

export const GeneralInformationPage = () => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { setState: setUserResponseId } = useUserResponseId();
  const navigate = useNavigate();
  const { processId, organizationId, stepId } = useParams();
  const templateId = useGetTemplateId(
    TemplateTypes.GENERAL_INFORMATION,
    organizationId,
    processId,
    stepId,
  );

  const { data: organization, isLoading: organizationIsLoading } =
    useGetOrganizationById(organizationId);
  const {
    id: userResponseId,
    data: templateData,
    isLoading: isLoadingTemplateData,
  } = useGetGeneralInformationTemplate(organizationId, processId, templateId);
  const { sections } = templateData;
  const { mutateAsync: updateGeneralInformationTemplate } =
    useUpdateGeneralInformationTemplate();

  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<CompletedSteps>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [defaultValues, setDefaultValues] = useState<DefaultValues>({});

  const validationSchema = generateValidationSchema(
    sections,
    t,
    currentLanguage,
  );
  const resolver = yupResolver(validationSchema);

  const methods = useForm({ defaultValues: {}, resolver });
  const { handleSubmit, trigger, watch, reset } = methods;
  const formData = watch();

  const isSectionFullyCompleted = useMemo(() => {
    const checkSectionCompletion = (sectionId: string) => {
      const sectionData = formData[sectionId];

      if (!sectionData) return false;

      const sectionElements = sections.find(
        (section) => section.id === sectionId,
      )?.elements;

      if (!sectionElements || sectionElements.length === 0) return false;

      return sectionElements.every((element) => {
        const { id, type } = element;
        const fieldValue = sectionData[id];

        if (type === ComponentTypes.MULTIPLE_TEXT) {
          if (Array.isArray(fieldValue) && fieldValue.length > 0) {
            return fieldValue.every(
              (item) =>
                item.field1?.[currentLanguage] &&
                item.field2?.[currentLanguage],
            );
          }
          return false;
        }

        if (type === ComponentTypes.FILE || type === ComponentTypes.PHONE) {
          return true;
        }

        if (typeof fieldValue === 'object' && fieldValue !== null) {
          return !!fieldValue?.[currentLanguage];
        }
      });
    };

    return checkSectionCompletion;
  }, [formData, sections, currentLanguage]);

  useEffect(() => {
    if (!isLoadingTemplateData) {
      setUserResponseId(userResponseId);
    }
  }, [isLoadingTemplateData, userResponseId, setUserResponseId]);

  useEffect(() => {
    if (!isLoadingTemplateData && sections.length > 0) {
      const newDefaultValues = generateDefaultValues(sections);

      setDefaultValues(newDefaultValues);
      reset(newDefaultValues);
    }
  }, [sections, isLoadingTemplateData, reset]);

  useEffect(() => {
    if (sections && steps.length === 0) {
      const initialSteps = sections.map(({ stepLabel }) => stepLabel);

      setSteps(initialSteps);
      setCompletedSteps(
        Object.fromEntries(initialSteps.map((step) => [step, false])),
      );
    }
  }, [sections, steps.length]);

  useEffect(() => {
    const checkAllSectionsCompleted = () => {
      const allSectionsCompleted = sections?.every((section) =>
        isSectionFullyCompleted(section.id),
      );

      setIsButtonDisabled(allSectionsCompleted);
    };

    checkAllSectionsCompleted();
  }, [sections, formData, isSectionFullyCompleted]);

  useEffect(() => {
    const updateCompletedSteps = async () => {
      for (const { stepLabel, id } of sections || []) {
        const isSectionCompleted = isSectionFullyCompleted(id);

        if (isSectionCompleted && !completedSteps[stepLabel]) {
          const isValid = await trigger(id);

          if (isValid) {
            setCompletedSteps((prevCompletedSteps) => ({
              ...prevCompletedSteps,
              [stepLabel]: isSectionCompleted,
            }));
          }
        } else if (!isSectionCompleted && completedSteps[stepLabel]) {
          setCompletedSteps((prevCompletedSteps) => ({
            ...prevCompletedSteps,
            [stepLabel]: isSectionCompleted,
          }));
        }
      }
    };

    updateCompletedSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, defaultValues, completedSteps]);

  useEffect(() => {
    const updateCompletedSteps = async () => {
      for (const { stepLabel, id } of sections || []) {
        const isSectionCompleted = isSectionFullyCompleted(id);

        if (isSectionCompleted && !completedSteps[stepLabel]) {
          const isValid = await trigger(id);

          if (isValid) {
            setCompletedSteps((prevCompletedSteps) => ({
              ...prevCompletedSteps,
              [stepLabel]: isSectionCompleted,
            }));
          }
        } else if (!isSectionCompleted && completedSteps[stepLabel]) {
          setCompletedSteps((prevCompletedSteps) => ({
            ...prevCompletedSteps,
            [stepLabel]: isSectionCompleted,
          }));
        }
      }
    };

    updateCompletedSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, formData, completedSteps]);

  if (isLoadingTemplateData || organizationIsLoading || !sections.length) {
    return <PageLoader />;
  }

  const handleStep = (step: string) => () => setActiveStep(steps.indexOf(step));

  const updateGeneralInformation = async (elementId?: string) => {
    const deserializedJson = deserializeFormData(
      methods.getValues(),
      templateData,
    );

    await updateGeneralInformationTemplate({
      data: {
        response: deserializedJson,
        mutatedElementId: elementId,
        templateName: 'General Information',
      },
      organizationId,
      processId,
      templateId,
    });
  };

  const onNextStepProceed = () => {
    const nextStepId = organization?.processes
      ?.find(({ _id }) => processId === _id)
      ?.steps.find(({ step }) =>
        step.name.toLowerCase().includes(TemplateTypes.DECOMPOSITION),
      )?.step?._id;

    const path = `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.PROCESSES}/${processId}/${Routes.STEPS}/${nextStepId}/${Routes.DETAILED_ASSESSMENT_SURVEY}`;

    navigate(path);
  };

  const processTitle = getProcessTitle(organization?.processes, processId);

  return (
    <SurveyLayout
      processTitle={t(processTitle)}
      sectionTitle={t('sections.general_information')}
    >
      <Grid container justifyContent="center" columnSpacing={4}>
        <Grid
          item
          xs={3}
          sx={{ position: 'sticky', top: 203, alignSelf: 'start' }}
        >
          <PaperLayout
            borderRadius={8}
            styles={{
              padding: 3,
              minHeight: `calc(100vh - ${surveyLayoutHeight}px)`,
            }}
          >
            <VerticalLinearStepper
              steps={steps}
              activeStep={activeStep}
              completedSteps={completedSteps}
              onStep={handleStep}
            />
          </PaperLayout>
        </Grid>

        <Grid container direction="column" alignItems="center" item xs={9}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onNextStepProceed)}>
              <StyledSectionsWrapper>
                {sections?.map((section) => (
                  <Fragment key={section.id}>
                    {getComponentByType({
                      element: section,
                      sectionId: section.id,
                      t,
                      onUpdateTemplate: updateGeneralInformation,
                    })}
                  </Fragment>
                ))}
              </StyledSectionsWrapper>
            </form>
          </FormProvider>
          <Button
            style={{ textAlign: 'center' }}
            variant="outlined"
            endIcon={<EastRoundedIcon />}
            disabled={!isButtonDisabled}
            onClick={onNextStepProceed}
          >
            {t('generalInformationPage.go_to_detailed_assessment')}
          </Button>
        </Grid>
      </Grid>
    </SurveyLayout>
  );
};
