import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'routes/constants';

import { Grid } from '@mui/material';

import SewageTreatmentIcon from 'assets/sewage-treatment-icon.svg';

import { theme } from 'configs/theme';

import { ProcessSection } from '../process-step/process-section';
import { StyledBlockWrapper, StyledTitle } from './styles';
import { ProcessItemProps, Section, SectionsAccumulator } from './types';

const sectionNameMap = {
  'general-information-survey': 'general_information',
  'detailed-assessment-survey': 'detailed_assessment',
} as const;

const downloadReportSection: Section = {
  sectionName: 'download_report',
  sectionIndex: 3,
  isDownloadReportStep: true,
};

export const ProcessItem = ({ process }: ProcessItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const { name, logo, bgGradient, steps, _id } = process;

  const sections: SectionsAccumulator = steps
    .sort((a, b) => a.stepIndex - b.stepIndex)
    .reduce((acc, curr) => {
      // @ts-expect-error: sectionPath may not directly map to sectionNameMap keys
      if (acc[curr.step.sectionPath]) return acc;
      // @ts-expect-error: TypeScript cannot infer the dynamic key relationship
      acc[curr.step.sectionPath] = {
        // @ts-expect-error: TypeScript expects explicit mapping for section names
        sectionName: sectionNameMap[curr.step.sectionPath],
        path: curr.step.sectionPath,
        stepId: curr.step._id,
        sectionIndex: Object.keys(acc).length + 1,
      };

      return acc;
    }, {});

  const allSections = [...Object.values(sections), downloadReportSection];

  const sectionsLength = allSections.length;

  const mainProcessInfoBlock = (
    <StyledBlockWrapper bgGradient={bgGradient}>
      <img
        src={logo ? logo : SewageTreatmentIcon}
        alt={t(`processes.${name}`)}
        style={{ width: 80 }}
      />

      <StyledTitle>{t(`processes.${name}`)}</StyledTitle>
    </StyledBlockWrapper>
  );

  return (
    <Grid
      container
      sx={{
        borderRadius: '8px',
        border: `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <Grid item xs={sectionsLength === 2 ? 4 : 3}>
        {mainProcessInfoBlock}
      </Grid>

      {allSections.map((section) => {
        const path = section.path
          ? `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.PROCESSES}/${_id}/${Routes.STEPS}/${section.stepId}/${section.path}`
          : undefined;

        return (
          <Grid
            key={section.sectionName}
            item
            xs={sectionsLength === 2 ? 4 : 3}
            sx={{ cursor: path ? 'pointer' : 'default' }}
            {...(path && { onClick: () => navigate(path) })}
          >
            <ProcessSection
              organizationId={organizationId}
              processId={_id}
              section={section}
              sectionsLength={sectionsLength}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
