import { Box, Grid, styled } from '@mui/material';

import { surveyLayoutHeight } from './constants';

export const StyledSectionsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StyledStickyGridItem = styled(Grid)({
  padding: 32,
  position: 'sticky',
  top: surveyLayoutHeight,
  alignSelf: 'start',
  overflowY: 'auto',
  height: `calc(100vh - ${surveyLayoutHeight}px)`,
});
