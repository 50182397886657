import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { RiskScale } from 'components/risk-scale/risk-scale';

export const RecommendedPriorityHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={4} />

        <Grid item xs={6}>
          <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
            {t('recommended_priority.risk_decrease_title')}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography
            sx={{ fontSize: 14, fontWeight: 700, textAlign: 'right' }}
          >
            {t('recommended_priority.estimated_cost_title')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={4} />
        <Grid item xs={6}>
          <RiskScale />
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </>
  );
};
