import { useLocation, useSearchParams } from 'react-router-dom';

export const useCurrentTab = (searchParam?: string): string => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  if (searchParam) {
    const tab = searchParams.get(searchParam);

    return tab || '';
  } else {
    const pathnamePieces = pathname.split('/');

    return pathnamePieces[pathnamePieces.length - 1];
  }
};
