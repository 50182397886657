import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledOrganizationInfoWrapper = styled(Box)({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  minWidth: 550,
  marginBottom: 32,
});
