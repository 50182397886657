import { useGetOrganizationById } from 'hooks/queries/organizations/organizations';

import { TemplateTypes } from 'models/template.model';

export const useGetTemplateId = (
  templateType: TemplateTypes,
  organizationId?: string,
  processId?: string,
  stepId?: string,
): string | undefined => {
  const { data: organization } = useGetOrganizationById(organizationId);

  if (!organization || !organization.processes) {
    return undefined;
  }

  const process = organization.processes.find(({ _id }) => processId === _id);

  if (!process || !process.steps) {
    return undefined;
  }

  const step = process.steps.find(({ step }) => stepId === step._id)?.step;

  if (!step || !step.templates) {
    return undefined;
  }

  const template = step.templates.find(
    ({ name }) => name.toLowerCase() === templateType,
  );

  return template?._id;
};
