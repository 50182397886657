import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { commentService } from 'services/comment.service';

import { AppContext } from 'context';

import {
  UseCreateCommentByUserResponseId,
  UseDeleteCommentById,
  UseUpdateCommentById,
} from './types';

export const useCreateCommentByUserResponseId = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ userResponseId, data }: UseCreateCommentByUserResponseId) =>
      await commentService.createCommentByUserResponseId(
        auth,
        userResponseId,
        data,
      ),
    {
      mutationKey: queryKeys.createCommentByUserResponseId,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getCommentsByUserResponseId);
        showNotification({
          isShowingNotification: true,
          type: Severity.Success,
          message: t('comments.successfully_created'),
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};

export const useUpdateCommentById = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ commentId, data }: UseUpdateCommentById) =>
      await commentService.updateCommentById(auth, commentId, data),
    {
      mutationKey: queryKeys.updateCommentById,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getCommentsByUserResponseId);
        showNotification({
          isShowingNotification: true,
          type: Severity.Success,
          message: t('comments.successfully_updated'),
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};

export const useDeleteCommentById = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ commentId }: UseDeleteCommentById) =>
      await commentService.deleteCommentById(auth, commentId),
    {
      mutationKey: queryKeys.deleteCommentById,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getCommentsByUserResponseId);

        showNotification({
          isShowingNotification: true,
          type: Severity.Success,
          message: t('comments.successfully_deleted'),
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
