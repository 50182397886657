import { Severity } from 'context/types';
import {
  useDeleteAttachmentById,
  useGetAttachmentUrl,
  useUploadAttachments,
} from 'hooks/mutations/attachments/attachments';
import {
  useDeleteCommentById,
  useUpdateCommentById,
} from 'hooks/mutations/comments/comments';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@mui/material';

import { FILE_NAME } from 'components/file-upload/constants';
import { MenuActions } from 'components/menu/types';

import { Attachment } from 'models/attachments.model';
import { Comment } from 'models/comment.model';

import { AppContext } from 'context';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { CommentBlock } from '../comment-block/comment-block';
import { CommentTextFieldProps } from '../comment-text-field/types';
import { MENU_OPTIONS } from './constants';
import {
  CommentButtonActions,
  CommentItemProps,
  CommentListItemProps,
} from './types';

export const CommentListItem: FC<CommentListItemProps> = ({
  comment,
  isCurrentUser,
  isLastComment,
}) => {
  const {
    _id,
    user: { name, avatar, email },
    message,
    timestamp,
    attachments,
  } = comment;

  const {
    userResponseId: { userResponseId },
    notification: { showNotification },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();

  const { mutateAsync: getAttachmentUrl } = useGetAttachmentUrl();
  const { mutateAsync: deleteAttachmentById } = useDeleteAttachmentById();
  const { mutateAsync: uploadAttachments } = useUploadAttachments();
  const { mutateAsync: updateCommentById } = useUpdateCommentById();
  const { mutateAsync: deleteCommentById } = useDeleteCommentById();

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [files, setFiles] = useState<Attachment[]>(attachments);
  const [commentValue, setCommentValue] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  useEffect(
    () => setCommentValue(message[currentLanguage]),
    [message, currentLanguage],
  );

  const handleOptionClick = async (action: MenuActions) => {
    switch (action) {
      case MenuActions.EDIT:
        setIsEditingMode(true);
        setIsTextFieldFocused(true);
        break;

      case MenuActions.DELETE:
        await deleteCommentById({ commentId: _id });
        break;

      default:
        break;
    }

    setAnchorEl(null);
  };

  const handleDelete = async (attachmentId: string) => {
    try {
      await deleteAttachmentById({ id: attachmentId });

      const filteredFiles = files.filter(
        ({ fileId }) => fileId !== attachmentId,
      );

      setFiles(filteredFiles);
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.file.delete_file', { error }),
      });
    }
  };

  const handleDownload = async (id: string) => {
    try {
      const downloadUrl = await getAttachmentUrl({ id });
      const link = document.createElement('a');

      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.file.get_file', { error }),
      });
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const formData = new FormData();

      Array.from(files).forEach((file) => formData.append(FILE_NAME, file));

      try {
        const uploadedUrls = await uploadAttachments({ data: formData });

        setFiles((prevFiles) => [...prevFiles, ...uploadedUrls]);
      } catch (error) {
        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: t('errors.file.upload_file', { error }),
        });
      }
    }
  };

  const handleCommentUpdate = async () => {
    if (_id && isEditingMode) {
      const commentData: Comment = {
        userResponseId,
        user: { name, avatar, email },
        timestamp: Date.now(),
        message: { [currentLanguage]: commentValue },
        attachments: files,
      };

      setIsEditingMode(false);

      await updateCommentById({
        commentId: _id,
        data: commentData,
      });
    }
  };

  const handleButtonClick = (action: CommentButtonActions) => {
    switch (action) {
      case CommentButtonActions.SAVE:
        handleCommentUpdate();
        break;

      case CommentButtonActions.CANCEL:
        setIsEditingMode(false);
        setCommentValue(message[currentLanguage]);
        setFiles(attachments);
        break;

      default:
        break;
    }
  };

  const commentTextFieldProps: CommentTextFieldProps = {
    isEditingMode,
    isTextFieldFocused,
    commentValue,
    onSetIsTextFieldFocused: setIsTextFieldFocused,
    onFileChange: handleFileChange,
    onSetCommentValue: setCommentValue,
  };

  const commentItemProps: CommentItemProps = {
    name,
    timestamp,
    message,
    options: MENU_OPTIONS,
    anchorEl,
    openMenu: open,
    onSetAnchorEl: setAnchorEl,
    onOptionClick: handleOptionClick,
    onButtonClick: handleButtonClick,
  };

  return (
    <>
      <CommentBlock
        avatar={avatar}
        attachments={files}
        isCurrentUser={isCurrentUser}
        commentTextFieldProps={commentTextFieldProps}
        commentItemProps={commentItemProps}
        onDelete={handleDelete}
        onDownload={handleDownload}
      />

      {!isLastComment && <Divider />}
    </>
  );
};
