import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledRectangle = styled(Box)<{ isActive: boolean }>(
  ({ isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    height: 24,
    backgroundColor: isActive ? theme.palette.primary.light : 'transparent',
    outline: `1px solid ${theme.palette.grey[100]}`,
  }),
);
