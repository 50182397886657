import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { OrganizationInfoModify } from 'components/organization-info/organization-info-modify';

export const AdminCreateOrganizationPage = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        py: 4,
        mx: 'auto',
        maxWidth: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ pb: 4, width: '100%' }}>
        <Typography variant="h5">{t('create_organization')}</Typography>
      </Box>

      <OrganizationInfoModify />
    </Box>
  );
};
