import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { Organization } from 'models/organization.model';
import { UserModel } from 'models/user.model';

import { organizationService } from 'services/organization.service';

import { AppContext } from 'context';

interface UseGetOrganizations {
  data?: Organization[];
  isLoading: boolean;
  isError: boolean;
}

interface UseGetOrganizationById {
  data?: Organization;
  isLoading: boolean;
  isError: boolean;
}

export const useGetOrganizations = (): UseGetOrganizations => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { user: user_metadata } = auth;

  const { data, isLoading, isError } = useQuery(
    queryKeys.getOrganizations,
    () => organizationService.getOrganizations(auth!),
    {
      enabled: !!user_metadata,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export const useGetOrganizationById = (id?: string): UseGetOrganizationById => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { user: user_metadata } = auth;

  const { data, isLoading, isError } = useQuery(
    queryKeys.getOrganizationById,
    () => organizationService.getOrganizationById(auth, id),
    {
      enabled: !!user_metadata,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};
