import { FC } from 'react';

import { Grid } from '@mui/material';

import { riskScaleData } from './constants';
import { RiskIndex, RiskLevel, RiskLevelContainer } from './styles';

export const RiskScale: FC = () => (
  <Grid container spacing={0.3}>
    {Object.entries(riskScaleData).map(([index, color]) => (
      <Grid key={index} item xs={2}>
        <RiskLevelContainer>
          <RiskIndex>{index}</RiskIndex>

          <RiskLevel backgroundColor={color} />
        </RiskLevelContainer>
      </Grid>
    ))}
  </Grid>
);
