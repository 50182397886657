import { Severity } from 'context/types';
import { memo, useContext, useEffect } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { AppContext } from 'context';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import {
  NEW_OVERALL_RISK_EFFECT_SCORE_FIELD_NAME,
  NEW_OVERALL_RISK_PROBABILITY_SCORE_FIELD_NAME,
  NEW_OVERALL_RISK_SECTION,
  NOT_ASSESSED_OVERALL_RISK_VALUE,
  OVERALL_RISK_SECTION,
  ScoreEnum,
} from '../constants';
import { StyledDropdownScoreWrapper, StyledFormControl } from './styles';
import { DropdownTemplateComponentProps } from './types';
import { isScoreField } from './utils';

export const DropdownTemplateComponent = memo(
  ({
    fieldName,
    element,
    onUpdateTemplate,
  }: DropdownTemplateComponentProps) => {
    const { options, label, styles, width } = element;
    const { isEnLanguage, currentLanguage } = useIsEnLanguage();
    const {
      notification: { showNotification },
    } = useContext(AppContext);
    const { t } = useTranslation();
    const { control, getValues, setValue, setError, clearErrors } =
      useFormContext();
    const formValues = getValues();

    const {
      [ScoreEnum.EFFECT_SCORE]: overallRiskEffectScore,
      [ScoreEnum.PROBABILITY_SCORE]: overallRiskProbabilityScore,
    } = formValues[OVERALL_RISK_SECTION] ?? {};

    const {
      [ScoreEnum.EFFECT_SCORE]: newOverallRiskEffectScore,
      [ScoreEnum.PROBABILITY_SCORE]: newOverallRiskProbabilityScore,
    } = formValues[NEW_OVERALL_RISK_SECTION] ?? {};

    const isOverallRiskEffectScore = isScoreField(
      fieldName,
      OVERALL_RISK_SECTION,
      ScoreEnum.EFFECT_SCORE,
    );
    const isOverallRiskProbabilityScore = isScoreField(
      fieldName,
      OVERALL_RISK_SECTION,
      ScoreEnum.PROBABILITY_SCORE,
    );
    const isNewOverallRiskProbabilityScore = isScoreField(
      fieldName,
      NEW_OVERALL_RISK_SECTION,
      ScoreEnum.PROBABILITY_SCORE,
    );

    const handleChange = (
      value: string,
      field: ControllerRenderProps<FieldValues, string>,
    ) => {
      field.onChange(value);

      if (isOverallRiskEffectScore && value !== newOverallRiskEffectScore) {
        setValue(NEW_OVERALL_RISK_EFFECT_SCORE_FIELD_NAME, value);
      }

      if (
        isOverallRiskProbabilityScore &&
        Number(value) < newOverallRiskProbabilityScore
      ) {
        showNotification({
          isShowingNotification: true,
          type: Severity.Warning,
          message: t(
            'failures_and_remedy.errors.reset_new_overall_risk_probability_score_value_title',
          ),
          subtitle: t(
            'failures_and_remedy.errors.reset_new_overall_risk_probability_score_value_subtitle',
          ),
          hideDuration: 'disabled',
        });

        setValue(
          NEW_OVERALL_RISK_PROBABILITY_SCORE_FIELD_NAME,
          NOT_ASSESSED_OVERALL_RISK_VALUE,
        );
      }
    };

    useEffect(() => {
      const fieldsAreNotAssessed =
        overallRiskEffectScore === NOT_ASSESSED_OVERALL_RISK_VALUE ||
        overallRiskProbabilityScore === NOT_ASSESSED_OVERALL_RISK_VALUE;

      if (isNewOverallRiskProbabilityScore) {
        if (
          fieldsAreNotAssessed &&
          newOverallRiskProbabilityScore !== NOT_ASSESSED_OVERALL_RISK_VALUE
        ) {
          setError(
            `${NEW_OVERALL_RISK_SECTION}.${ScoreEnum.PROBABILITY_SCORE}`,
            {
              message: t('failures_and_remedy.errors.initial_risk_error'),
            },
          );
        } else {
          clearErrors(
            `${NEW_OVERALL_RISK_SECTION}.${ScoreEnum.PROBABILITY_SCORE}`,
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      formValues,
      isNewOverallRiskProbabilityScore,
      setError,
      clearErrors,
      t,
    ]);

    return (
      <Controller
        name={fieldName}
        control={control}
        defaultValue={NOT_ASSESSED_OVERALL_RISK_VALUE}
        render={({ field, fieldState: { error, isDirty } }) => (
          <StyledFormControl width={width} error={!!error} sx={styles}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start', mx: 'unset', width: '100%' }}
              control={
                !isEnLanguage ? (
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    value={field.value}
                    IconComponent={() => (
                      <ExpandMoreIcon fontSize="small" sx={{ mr: 3 }} />
                    )}
                    onChange={(event) =>
                      handleChange(event.target.value, field)
                    }
                    onBlur={() => isDirty && onUpdateTemplate?.()}
                  >
                    <MenuItem value={NOT_ASSESSED_OVERALL_RISK_VALUE}>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledDropdownScoreWrapper>
                          <HelpOutlineRoundedIcon />
                        </StyledDropdownScoreWrapper>

                        <Typography>
                          {t('failures_and_remedy.dropdown_not_assessed_title')}
                        </Typography>
                      </Box>
                    </MenuItem>

                    {options.map(({ label, value }) => {
                      const hideOption =
                        isNewOverallRiskProbabilityScore &&
                        value > overallRiskProbabilityScore;

                      return (
                        <MenuItem
                          value={value}
                          key={value}
                          sx={{ display: hideOption ? 'none' : 'flex' }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <StyledDropdownScoreWrapper>
                              {value}
                            </StyledDropdownScoreWrapper>

                            <Typography>{label[currentLanguage]}</Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Typography sx={{ marginBottom: 0 }}>
                    <strong>{field.value}</strong>{' '}
                    {options.find((op) => op.value === +field.value)?.label?.[
                      currentLanguage
                    ] ?? t('risk_definitions.not_assessed')}
                  </Typography>
                )
              }
              label={
                <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                  {t(label)}
                </Typography>
              }
              labelPlacement="top"
            />

            <FormHelperText>{error?.message}</FormHelperText>
          </StyledFormControl>
        )}
      />
    );
  },
);

DropdownTemplateComponent.displayName = 'DropdownTemplateComponent';
