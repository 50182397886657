import { FC } from 'react';

import Typography from '@mui/material/Typography';

import { DescriptionTitle, DescriptionWrapper } from './styles';

interface InstructionsBlockProps {
  title: string;
  description: string;
}
export const DescriptionBlock: FC<InstructionsBlockProps> = ({
  title,
  description,
}) => {
  return (
    <DescriptionWrapper>
      <DescriptionTitle>{title}</DescriptionTitle>
      <Typography component="p">{description}</Typography>
    </DescriptionWrapper>
  );
};
