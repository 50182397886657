import { Dispatch, SetStateAction } from 'react';

import { MenuActions } from 'components/menu/types';

import { Comment, MessageType } from 'models/comment.model';

export interface CommentListItemProps {
  comment: Comment;
  isCurrentUser: boolean;
  isLastComment: boolean;
}

export enum CommentButtonActions {
  SAVE = 'save',
  CANCEL = 'cancel',
}

export interface CommentItemProps {
  name: string | undefined;
  timestamp: number;
  message: MessageType;
  options: MenuActions[];
  anchorEl: HTMLElement | null;
  openMenu: boolean;
  onSetAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  onOptionClick: (action: MenuActions) => void;
  onButtonClick: (action: CommentButtonActions) => void;
}
