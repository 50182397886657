import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

export const AccountMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useAuth0();
  const { name, picture } = user!;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = (navigateTo?: string) => {
    setAnchorEl(null);
    navigateTo && navigate(navigateTo);
  };

  const handleLogout = () => {
    logout({
      logoutParams: { returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL },
    });
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <Avatar alt={name} src={picture} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        <MenuItem onClick={() => handleLogout()}>{t('logout')}</MenuItem>
      </Menu>
    </>
  );
};
