import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';

import { PageLoader } from 'components/page-loader/page-loader';

import defaultLogo from 'assets/org_img_default.png';

import { StyledOrganizationInfoWrapper } from './styles';
import { OrganizationInfoProps } from './types';

export const OrganizationInfo: FC<OrganizationInfoProps> = ({
  organization,
  withLink = false,
}) => {
  const { t } = useTranslation();

  const [isCopiedOrganizationId, setIsCopiedOrganizationId] = useState(false);

  if (!organization) {
    return <PageLoader />;
  }

  const { name, address, phones, edrpouCode, logoUrl, _id } = organization;

  return (
    <StyledOrganizationInfoWrapper>
      <img
        alt={name}
        src={logoUrl ?? defaultLogo}
        style={{ width: 80, height: 80, marginRight: 24, objectFit: 'cover' }}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 1.5 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 800 }}>
            {withLink ? (
              <Link component={RouterLink} to={organization._id}>
                {name}
              </Link>
            ) : (
              name
            )}
          </Typography>

          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
            {address}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {phones.map((number) => (
            <Typography sx={{ fontSize: 14, mr: 3 }} key={number}>
              {number}
            </Typography>
          ))}

          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: 14, mr: 1 }}>
              {t('edrpou_code')}:
            </Typography>
            <Typography sx={{ fontSize: 14 }}>{edrpouCode}</Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 14, mr: 1 }}>
              {t('organization_id')}:
            </Typography>

            <Typography sx={{ fontSize: 14 }}>{_id}</Typography>

            <Tooltip
              title={t(
                isCopiedOrganizationId
                  ? 'copied_to_clipboard'
                  : 'copy_organization_id',
              )}
            >
              <IconButton
                onClick={() => {
                  setIsCopiedOrganizationId(true);
                  navigator.clipboard.writeText(_id);
                }}
                onMouseLeave={() => setIsCopiedOrganizationId(false)}
                sx={{ ml: 1 }}
              >
                <ContentCopyIcon sx={{ height: 15, width: 15 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </StyledOrganizationInfoWrapper>
  );
};
