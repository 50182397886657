import { Box, Typography, styled } from '@mui/material';

import { theme } from 'configs/theme';

import { StyledBlockWrapperProps } from './types';

export const StyledBlockWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgGradient',
})<StyledBlockWrapperProps>(({ bgGradient }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 290,
  padding: 32,
  background: bgGradient,
  borderRadius: '8px 0 0 8px',
  backgroundColor: theme.palette.common.white,
}));

export const StyledTitle = styled(Typography)({
  fontSize: 24,
  fontWeight: 800,
  whiteSpace: 'wrap',
  overflowWrap: 'break-word',
  maxWidth: 220,
  color: theme.palette.common.white,
});
