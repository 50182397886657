import { useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, TextField, Typography } from '@mui/material';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { CHILDREN_MAX_LENGTH } from '../tree/constants';
import { EditableItemProps } from './types';

const iconStylesOnHover = {
  '&:hover': { bgcolor: 'background.default', borderRadius: '4px' },
};

export const EditableItem = ({ node, childLastIndex }: EditableItemProps) => {
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();
  const { title, changeTitle, removeNode, addChild } = node;
  const [showIconsOnHover, setShowIconsOnHover] = useState(false);

  return (
    <Box
      sx={{ display: 'flex', width: '100%' }}
      onMouseEnter={() => setShowIconsOnHover(true)}
      onMouseLeave={() => setShowIconsOnHover(false)}
    >
      {isEnLanguage ? (
        <Typography sx={{ marginBottom: 0 }}>
          {title[currentLanguage]}
        </Typography>
      ) : (
        <TextField
          size="small"
          variant="outlined"
          defaultValue={title[currentLanguage]}
          placeholder="New Step"
          sx={{ maxWidth: 500 }}
          fullWidth
          onBlur={(event) =>
            changeTitle &&
            title[currentLanguage] !== event.target.value.trim() &&
            changeTitle(event.target.value)
          }
        />
      )}

      {showIconsOnHover && !isEnLanguage && (
        <Box sx={{ ml: 1.5 }}>
          {childLastIndex !== CHILDREN_MAX_LENGTH && (
            <IconButton
              onClick={() => addChild && addChild()}
              sx={{ mr: 1, ...iconStylesOnHover }}
            >
              <AddCircleOutlineRoundedIcon color="primary" />
            </IconButton>
          )}

          <IconButton
            onClick={() => removeNode && removeNode()}
            sx={iconStylesOnHover}
          >
            <DeleteOutlineIcon color="primary" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
