import { Auth0ContextInterface } from '@auth0/auth0-react';

import { JsonTemplate, Template } from 'models/template.model';
import { UserModel } from 'models/user.model';

import { axiosGet, axiosPut } from './external-api.service';

export const potentialFailuresTemplateService = {
  getPotentialFailuresTemplate: (
    auth: Auth0ContextInterface<UserModel>,
    templateId?: string,
    organizationId?: string,
    processId?: string,
  ) => {
    if (!templateId) return;

    return axiosGet<Template>(
      auth,
      `user-responses/${templateId}?organizationId=${organizationId}&processId=${processId}`,
    );
  },

  updatePotentialFailuresTemplate: (
    auth: Auth0ContextInterface<UserModel>,
    templateId: string | undefined,
    data: {
      response: JsonTemplate;
      mutatedElementId?: string;
      templateName: 'Potential Failures';
    },
    references: { organizationId?: string; processId?: string },
  ) => {
    if (!templateId) return;
    return axiosPut<
      Template,
      {
        references: { organizationId?: string; processId?: string };
        additionalData: { mutatedElementId?: string; templateName: string };
        jsonTemplate: JsonTemplate;
      }
    >(auth, `user-responses/${templateId}`, {
      jsonTemplate: data.response,
      additionalData: {
        mutatedElementId: data.mutatedElementId,
        templateName: data.templateName,
      },
      references,
    });
  },
};
