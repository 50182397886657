import { UseUpdateCompositionData } from 'hooks/mutations/composition/types';

import { Auth0ContextInterface } from '@auth0/auth0-react';

import { NodeTree } from 'models/composition.model';
import { UserModel } from 'models/user.model';

import { axiosGet, axiosPut } from './external-api.service';

export const compositionService = {
  getCompositionData: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
    templateId: string | undefined,
  ) => {
    if (!organizationId || !processId || !templateId) return;

    return axiosGet<NodeTree>(
      auth,
      `user-responses/${organizationId}/${processId}/${templateId}`,
    );
  },

  updateCompositionData: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
    templateId: string | undefined,
    data: UseUpdateCompositionData['data'],
  ) => {
    if (!organizationId || !processId || !templateId) return;

    return axiosPut<NodeTree, UseUpdateCompositionData['data']>(
      auth,
      `user-responses/${organizationId}/${processId}/${templateId}`,
      data,
    );
  },
};
