import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { compositionService } from 'services/composition.service';

import { AppContext } from 'context';

import { UseUpdateCompositionData } from './types';

export const useUpdateCompositionData = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({
      data,
      organizationId,
      processId,
      templateId,
    }: UseUpdateCompositionData) =>
      await compositionService.updateCompositionData(
        auth,
        organizationId,
        processId,
        templateId,
        data,
      ),
    {
      mutationKey: queryKeys.updateCompositionData,
      onSuccess: (data) =>
        queryClient.invalidateQueries({
          queryKey: [
            queryKeys.getCompositionData,
            data?.organizationId,
            data?.processId,
            data?.templateId,
          ],
        }),
      onSettled: (_, __, variables) => {
        variables.onSettledCb?.();
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
