import { Box, Chip, styled } from '@mui/material';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const StyledChip = styled(Chip)({
  borderRadius: 4,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  height: 40,
});

export const StyledFileUploadWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 12,
});
