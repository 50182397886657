import { MuiTelInput } from 'mui-tel-input';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, Typography } from '@mui/material';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { PhoneTemplateComponentComponentProps } from './types';

export const PhoneTemplateComponent = ({
  elementId,
  placeholder,
  label,
  fieldName,
  styles,
  width,
  onUpdateTemplate,
}: PhoneTemplateComponentComponentProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();

  return (
    <Controller
      key={fieldName}
      name={fieldName}
      control={control}
      render={({ field, fieldState: { error, isDirty } }) => (
        <FormControlLabel
          sx={{
            alignItems: 'flex-start',
            mx: 'unset',
            width: width ?? '100%',
            ...styles,
          }}
          control={
            isEnLanguage ? (
              <Typography sx={{ marginBottom: 0 }}>
                {field?.value?.[currentLanguage] || t('empty_field')}
              </Typography>
            ) : (
              <MuiTelInput
                {...field}
                size="small"
                error={!!error}
                helperText={error?.message}
                placeholder={placeholder}
                value={field?.value?.[currentLanguage]}
                onChange={(e) => {
                  const updatedValue = {
                    ...field.value,
                    [currentLanguage]: e,
                  };

                  field.onChange(updatedValue);
                }}
                defaultCountry="UA"
                disableDropdown
                fullWidth
                inputProps={{
                  maxLength: 16,
                  readOnly: isEnLanguage,
                  disabled: isEnLanguage,
                }}
                onBlur={() => isDirty && onUpdateTemplate?.(elementId)}
              />
            )
          }
          label={
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
              {label}
            </Typography>
          }
          labelPlacement="top"
        />
      )}
    />
  );
};
