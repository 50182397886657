import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { riskScoreService } from 'services/risk-score.service';

import { AppContext } from 'context';

import { DEFAULT_RISK_SCORE_VALUES } from './constants';
import { UseGetRiskScoresByProcessId } from './types';

export const useGetRiskScoresByProcessId = (
  processId?: string,
): UseGetRiskScoresByProcessId => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError } = useQuery(
    [queryKeys.getRiskScoresByProcessId, processId],
    () => riskScoreService.getRiskScoresByProcessId(auth, processId),
    {
      enabled: !!processId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data: data?.scores ?? DEFAULT_RISK_SCORE_VALUES,
    isLoading,
    isError,
  };
};
