import { ScoreListItem } from 'components/scores-list';

export const generateMatrix = (
  rows: ScoreListItem[],
  cols: ScoreListItem[],
) => {
  const matrix: number[][] = [];

  for (let i = 0; i < rows.length; i++) {
    const row = [];

    for (let j = 0; j < cols.length; j++) {
      const value = rows[i].score * cols[j].score;

      row.push(value);
    }
    matrix.push(row);
  }
  return matrix;
};
