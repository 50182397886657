import { Auth0ContextInterface } from '@auth0/auth0-react';

import { GetRecommendedPriorityRequest } from 'models/recommended-priority.model';
import { UserModel } from 'models/user.model';

import { axiosGet } from './external-api.service';

export const recommendedPriorityService = {
  getRecommendedPriority: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
  ) => {
    if (!organizationId || !processId) return;

    return axiosGet<GetRecommendedPriorityRequest>(
      auth,
      `recommended-priority/${organizationId}/${processId}`,
    );
  },
};
