import { useContext } from 'react';

import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { CircularProgress, Snackbar, Typography } from '@mui/material';

import { AppContext } from 'context';

import { StyledAlert } from './styles';

export const Notification = () => {
  const {
    notification: {
      type,
      message,
      hideDuration,
      subtitle,
      closeNotification,
      isShowingNotification,
    },
  } = useContext(AppContext);

  const title = message instanceof Error ? message.message : message;
  const autoHideDuration =
    hideDuration === 'disabled' ? undefined : hideDuration;

  return (
    <Snackbar
      open={isShowingNotification}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      onClose={closeNotification}
      sx={{ top: { xs: 104 } }}
    >
      <StyledAlert
        severity={type}
        onClose={closeNotification}
        iconMapping={{
          info: <CircularProgress size={24} />,
          success: <TaskAltRoundedIcon sx={{ color: 'success.main' }} />,
        }}
      >
        <Typography sx={{ fontSize: 18, fontWeight: 600 }}>{title}</Typography>

        {subtitle && <Typography sx={{ fontSize: 14 }}>{subtitle}</Typography>}
      </StyledAlert>
    </Snackbar>
  );
};
