import { Auth0ContextInterface } from '@auth0/auth0-react';

import { Scores } from 'models/risk-score.model';
import { UserModel } from 'models/user.model';

import { axiosGet } from './external-api.service';

export const riskScoreService = {
  getRiskScoresByProcessId: (
    auth: Auth0ContextInterface<UserModel>,
    processId: string | undefined,
  ) => {
    if (!processId) return;

    return axiosGet<Scores>(auth, `risk-scores/${processId}`);
  },
};
