import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { recommendedPriorityService } from 'services/recommended-priority';

import { AppContext } from 'context';

import { UseGetRecommendedPriorityData } from './types';

// TODO: REFACTOR and unify with general-info and composition
export const useGetRecommendedPriority = (
  organizationId?: string,
  processId?: string,
): UseGetRecommendedPriorityData => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError } = useQuery(
    [queryKeys.getRecommendedPriority, organizationId, processId],
    () =>
      recommendedPriorityService.getRecommendedPriority(
        auth,
        organizationId,
        processId,
      ),
    {
      enabled: !!processId && !!organizationId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};
