import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Collapse } from '@mui/material';

import { NOT_ASSESSED_OVERALL_RISK_VALUE } from '../overall-risk-template/constants';
import {
  StyledAccordionDetails,
  StyledAccordionIconWrapper,
  StyledAccordionTypography,
} from './styles';
import { CollapsibleSectionsProps } from './types';
import { getIconWrapperBgColor, getRiskScore, renderIcon } from './utils';

export const CollapsibleSection = ({
  title,
  content,
  sectionId,
  isSectionCompleted,
  isSectionCollapsed,
  riskScores,
  onCollapsibleSection,
}: CollapsibleSectionsProps) => {
  const [riskScore, setRiskScore] = useState<string | number>(
    NOT_ASSESSED_OVERALL_RISK_VALUE,
  );
  const { getValues } = useFormContext();

  const formValues = getValues();

  useEffect(() => {
    setRiskScore(getRiskScore(formValues, sectionId));
  }, [formValues, sectionId]);

  return (
    <Accordion
      disableGutters
      sx={{ boxShadow: 'unset' }}
      expanded={isSectionCollapsed}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => onCollapsibleSection && onCollapsibleSection(sectionId)}
      >
        <StyledAccordionIconWrapper
          bgcolor={getIconWrapperBgColor(riskScore, riskScores)}
        >
          {renderIcon(riskScore, isSectionCompleted)}
        </StyledAccordionIconWrapper>

        {title && (
          <StyledAccordionTypography>{title}</StyledAccordionTypography>
        )}
      </AccordionSummary>

      <StyledAccordionDetails>
        <Collapse in={isSectionCollapsed}>{content}</Collapse>
      </StyledAccordionDetails>
    </Accordion>
  );
};
