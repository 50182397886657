import { useTranslation } from 'react-i18next';

import { Language } from 'components/language-switcher/types';

export const useIsEnLanguage = () => {
  const { i18n } = useTranslation();

  const isEnLanguage = i18n.language === Language.EN;

  return { isEnLanguage, currentLanguage: i18n.language as Language };
};
