import { SyntheticEvent, useEffect, useState } from 'react';

import { SimpleTreeView } from '@mui/x-tree-view';

import { NOT_ASSESSED_OVERALL_RISK_VALUE } from 'components/overall-risk-template/constants';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { LAST_NODE_INDEX, NODE_INDEX } from '../constants';
import { TreeItemLabel } from '../tree-item-label/tree-item-label';
import { StyledTreeItem } from './styles';
import { TreeNode, TreeNodesStepperProps } from './types';

export const TreeNodesStepper = ({
  data,
  selectedId,
  riskScores,
  onSelectedTemplateId,
}: TreeNodesStepperProps) => {
  const { currentLanguage } = useIsEnLanguage();
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const renderTree = (nodes: TreeNode[] | null, isFirstElement = false) =>
    nodes
      ?.filter(({ title }) => title[currentLanguage]?.trim() !== '')
      .map(
        (
          { id, childrenCount, title, children, overallRisk, newOverallRisk },
          index,
        ) => {
          const overallRiskColor = overallRisk
            ? riskScores[overallRisk]?.color
            : riskScores[NOT_ASSESSED_OVERALL_RISK_VALUE]?.color;
          const newOverallRiskColor = newOverallRisk
            ? riskScores[newOverallRisk]?.color
            : riskScores[NOT_ASSESSED_OVERALL_RISK_VALUE]?.color;

          return (
            <StyledTreeItem
              key={id}
              itemId={id}
              label={
                <TreeItemLabel
                  title={title[currentLanguage]}
                  isFirstElement={isFirstElement}
                  index={index + NODE_INDEX}
                  overallRiskColor={overallRiskColor}
                  newOverallRiskColor={newOverallRiskColor}
                />
              }
              isFirstElement={isFirstElement}
              isLastElement={childrenCount === LAST_NODE_INDEX}
            >
              {children.length > 0 && renderTree(children)}
            </StyledTreeItem>
          );
        },
      );

  const handleExpandedItemsChange = (
    event: SyntheticEvent<Element, Event>,
    itemIds: string[],
  ) => {
    if (event.target instanceof Element) {
      const closestElement = event.target.closest('.MuiTreeItem-iconContainer');

      closestElement && setExpandedNodes(itemIds);
    }
  };

  useEffect(() => {
    const findNodesWithChildren = (nodes: TreeNode[]) => {
      const expandedNodes: string[] = [];

      const traverseNodes = (node: TreeNode) => {
        if (!node.isCollapsed) {
          expandedNodes.push(node.id);
          node.children.forEach(traverseNodes);
        }
      };

      nodes.forEach(traverseNodes);
      setExpandedNodes(expandedNodes);
    };

    if (data) {
      findNodesWithChildren(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SimpleTreeView
      onItemSelectionToggle={(_, itemId) => onSelectedTemplateId(itemId)}
      selectedItems={selectedId}
      expandedItems={expandedNodes}
      onExpandedItemsChange={handleExpandedItemsChange}
    >
      {renderTree(data, true)}
    </SimpleTreeView>
  );
};
