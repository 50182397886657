import { NOT_ASSESSED_OVERALL_RISK_VALUE } from 'components/overall-risk-template/constants';

export interface GetRecommendedPriorityRequest {
  _id: string;
  processId: string;
  organizationId: string;
  results: RecommendedPriorityItem[];
  levels: number[];
}

export interface RecommendedPriorityItem {
  id: string;
  name: { en: string; ua: string } | string;
  parentNames: { en: string; ua: string }[] | string[];
  nestedLevel: number;
  overallRiskLevelDifference: number | typeof NOT_ASSESSED_OVERALL_RISK_VALUE;
  initialOverallRiskLevel: number | typeof NOT_ASSESSED_OVERALL_RISK_VALUE;
  newOverallRiskLevel: number | typeof NOT_ASSESSED_OVERALL_RISK_VALUE;
  amount: number;
}

export enum RecommendedPriorityDataSortBy {
  DECOMPOSITION = 'decomposition',
  PRIORITY = 'priority',
  COST = 'cost',
}
