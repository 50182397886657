import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import * as yup from 'yup';

import { Box, Typography } from '@mui/material';

import { CollapsibleSection } from 'components/collapsible-section/collapsible-section';
import { CommentTemplateComponent } from 'components/comment-template/comment-template';
import { DEFAULT_CURRENCY_VALUE } from 'components/currency-template/constants';
import { CurrencyTemplateComponent } from 'components/currency-template/currency-template';
import { InputFileUpload } from 'components/file-upload/file-upload';
import { Language } from 'components/language-switcher/types';
import { MAIN_ISSUES_FIELDS_INITIAL_STATE } from 'components/multiple-text/constants';
import { MultipleTextList } from 'components/multiple-text/multiple-text-list/multiple-text-list';
import {
  NEW_OVERALL_RISK_SECTION,
  NOT_ASSESSED_OVERALL_RISK_VALUE,
  OVERALL_RISK_SECTION,
} from 'components/overall-risk-template/constants';
import { OverallRiskComponentTemplate } from 'components/overall-risk-template/overall-risk-template/overall-risk-template';
import { PaperLayout } from 'components/paper-layout/paper-layout';
import { PhoneTemplateComponent } from 'components/phone-template/phone-template';
import { TextTemplateComponent } from 'components/text-template/text-template';
import { TreeNode } from 'components/tree-nodes-stepper/tree-nodes-stepper/types';

import { Node } from 'models/composition.model';
import { Process } from 'models/process.model';
import { RiskScores } from 'models/risk-score.model';
import {
  ComponentTemplate,
  ComponentTypes,
  DefaultValues,
  Element,
  JsonTemplate,
  MultipleTextItemTemplate,
  OverallRiskType,
  SectionStates,
  SectionTemplate,
} from 'models/template.model';

import {
  MAX_LENGTH_MULTILINE_TEXT_CHARACTERS,
  MAX_LENGTH_TEXT_CHARACTERS,
  MIN_LENGTH_CHARACTERS,
  emptyString,
} from 'constants/index';

export const titleOverallRisk = 'Define risks';
export const titleNewOverallRisk = 'Risk prevention';

export const generateDefaultValues = (
  sections: SectionTemplate[],
): DefaultValues => {
  const defaultValues: DefaultValues = {};

  if (Array.isArray(sections)) {
    sections.forEach(
      ({ elements, id: sectionId, overallRisk, newOverallRisk }) => {
        defaultValues[sectionId] = {};

        if (elements && Array.isArray(elements)) {
          elements.forEach((element) => {
            const { id, type, value, items } = element;

            switch (type) {
              case ComponentTypes.TEXT:
                defaultValues[sectionId][id] = value ?? emptyString;
                break;

              case ComponentTypes.PHONE:
                defaultValues[sectionId][id] = value ?? emptyString;
                break;

              case ComponentTypes.COMMENT:
                defaultValues[sectionId][id] = value ?? emptyString;
                break;

              case ComponentTypes.CURRENCY:
                defaultValues[sectionId][id] = value ?? DEFAULT_CURRENCY_VALUE;
                break;

              case ComponentTypes.MULTIPLE_TEXT:
                defaultValues[sectionId][id] = items ?? [
                  MAIN_ISSUES_FIELDS_INITIAL_STATE,
                ];
                break;

              case ComponentTypes.OVERALL_RISK:
                defaultValues[sectionId].overallRisk =
                  overallRisk ?? NOT_ASSESSED_OVERALL_RISK_VALUE;
                defaultValues[sectionId].newOverallRisk =
                  newOverallRisk ?? NOT_ASSESSED_OVERALL_RISK_VALUE;

                defaultValues[sectionId].effectScore =
                  element.effectScore.value ?? NOT_ASSESSED_OVERALL_RISK_VALUE;
                defaultValues[sectionId].probabilityScore =
                  element.probabilityScore.value ??
                  NOT_ASSESSED_OVERALL_RISK_VALUE;
                break;

              case ComponentTypes.FILE:
                defaultValues[sectionId][id] = value ?? [];
                break;

              default:
                break;
            }
          });
        }
      },
    );
  }

  return defaultValues;
};

export interface GetComponentByTypeArgs {
  element: ComponentTemplate;
  sectionId: string;
  isPartOfSection?: boolean;
  t: TFunction;
  riskScores?: RiskScores;
  isSectionCollapsed?: boolean;
  onCollapsibleSection?: (sectionId: string) => void;
  onUpdateTemplate: (elementId?: string) => Promise<void>;
}

export const getComponentByType = ({
  element,
  sectionId,
  isPartOfSection = false,
  t,
  riskScores,
  isSectionCollapsed,
  onCollapsibleSection,
  onUpdateTemplate,
}: GetComponentByTypeArgs): ReactNode => {
  const {
    id,
    type,
    styles,
    width,
    label,
    placeholder,
    description,
    rows,
    inputProps,
    inputType,
    isCollapsible,
    isCompleted,
    title,
    elements,
  } = element;

  const fieldName = isPartOfSection ? `${sectionId}.${id}` : id;

  if (isPartOfSection) {
    switch (type) {
      case ComponentTypes.TEXT:
        return (
          <TextTemplateComponent
            elementId={id}
            key={fieldName}
            placeholder={placeholder}
            inputType={inputType}
            inputProps={inputProps}
            fieldName={fieldName}
            label={t(label ?? '')}
            styles={styles}
            width={width}
            onUpdateTemplate={onUpdateTemplate}
          />
        );

      case ComponentTypes.PHONE:
        return (
          <PhoneTemplateComponent
            elementId={id}
            key={fieldName}
            placeholder={placeholder}
            fieldName={fieldName}
            label={t(label ?? '')}
            styles={styles}
            width={width}
            onUpdateTemplate={onUpdateTemplate}
          />
        );

      case ComponentTypes.COMMENT:
        return (
          <CommentTemplateComponent
            elementId={id}
            key={fieldName}
            placeholder={t(placeholder ?? '')}
            description={t(description ?? '')}
            fieldName={fieldName}
            label={t(label ?? '')}
            rows={rows}
            inputProps={inputProps}
            styles={styles}
            width={width}
            onUpdateTemplate={onUpdateTemplate}
          />
        );

      case ComponentTypes.FILE:
        return (
          <InputFileUpload
            key={fieldName}
            fieldName={fieldName}
            element={element}
            onUpdateTemplate={onUpdateTemplate}
          />
        );

      case ComponentTypes.MULTIPLE_TEXT:
        return (
          <MultipleTextList
            key={fieldName}
            element={element}
            sectionId={sectionId}
            id={id}
            onUpdateTemplate={onUpdateTemplate}
          />
        );

      case ComponentTypes.OVERALL_RISK:
        return (
          <OverallRiskComponentTemplate
            key={fieldName}
            element={element}
            sectionId={sectionId}
            riskScores={riskScores}
            onUpdateTemplate={onUpdateTemplate}
          />
        );

      case ComponentTypes.CURRENCY:
        return (
          <CurrencyTemplateComponent
            key={fieldName}
            placeholder={t(placeholder ?? '')}
            inputProps={inputProps}
            fieldName={fieldName}
            label={t(label ?? '')}
            styles={styles}
            width={width}
            onUpdateTemplate={onUpdateTemplate}
          />
        );

      default:
        break;
    }
  } else {
    switch (type) {
      case ComponentTypes.SECTION:
        const paperStyles = {
          marginBottom: 3,
          paddingTop: !isCollapsible ? '24px' : '',
          paddingBottom: !isSectionCollapsed || !isCollapsible ? '24px' : '',
        };

        const sectionContent = (
          <Box sx={{ px: 3 }}>
            {description && (
              <Typography sx={{ mb: 2 }}>{t(description)}</Typography>
            )}

            {elements?.map((element) =>
              getComponentByType({
                element,
                sectionId: fieldName,
                isPartOfSection: true,
                t,
                riskScores,
                onUpdateTemplate,
              }),
            )}
          </Box>
        );

        return (
          <PaperLayout borderRadius={8} styles={paperStyles} key={id}>
            {isCollapsible ? (
              <CollapsibleSection
                title={t(title ?? '')}
                sectionId={sectionId}
                isSectionCompleted={isCompleted}
                isSectionCollapsed={!isSectionCollapsed}
                onCollapsibleSection={onCollapsibleSection}
                content={sectionContent}
                riskScores={riskScores}
              />
            ) : (
              <Typography sx={{ fontWeight: 800, fontSize: 18, px: 3 }}>
                {t(title ?? '')}
              </Typography>
            )}

            {!isCollapsible && sectionContent}
          </PaperLayout>
        );

      default:
        break;
    }
  }
};

export const generateValidationSchema = (
  sections: SectionTemplate[],
  t: TFunction,
  currentLanguage: Language,
) => {
  const schemaObject: Record<string, yup.AnyObjectSchema> = {};

  if (Array.isArray(sections)) {
    sections.forEach(({ id: sectionId, elements }) => {
      const sectionSchema: Record<string, yup.Schema> = {};

      if (elements && Array.isArray(elements)) {
        elements.forEach((element) => {
          const { id: fieldId, type, label } = element;

          switch (type) {
            case ComponentTypes.TEXT:
              sectionSchema[fieldId] = yup
                .string()
                .transform((value) => {
                  const currentValue = value?.[currentLanguage];

                  return typeof currentValue === 'string'
                    ? currentValue.trim()
                    : currentValue;
                })
                .required(
                  `${t(label ? 'field' : '')} ${t(label ?? 'this_field')} ${t(
                    'validation.is_required',
                  )}`,
                )
                .min(
                  MIN_LENGTH_CHARACTERS,
                  `${t(label ?? 'this_field')} ${t('validation.min_chars', {
                    num: MIN_LENGTH_CHARACTERS,
                  })}`,
                )
                .max(
                  MAX_LENGTH_TEXT_CHARACTERS,
                  `${t(label ?? 'this_field')} ${t('validation.max_chars', {
                    num: MAX_LENGTH_TEXT_CHARACTERS,
                  })}`,
                );
              break;

            case ComponentTypes.COMMENT:
              sectionSchema[fieldId] = yup
                .string()
                .transform((value) => {
                  const currentValue = value?.[currentLanguage];

                  return typeof currentValue === 'string'
                    ? currentValue.trim()
                    : currentValue;
                })
                .required(
                  `${t(label ? 'field' : '')} ${t(label ?? 'this_field')} ${t(
                    'validation.is_required',
                  )}`,
                )
                .min(
                  MIN_LENGTH_CHARACTERS,
                  `${t(label ?? 'this_field')} ${t('validation.min_chars', {
                    num: MIN_LENGTH_CHARACTERS,
                  })}`,
                )
                .max(
                  MAX_LENGTH_MULTILINE_TEXT_CHARACTERS,
                  `${t(label ?? 'this_field')} ${t('validation.max_chars', {
                    num: MAX_LENGTH_MULTILINE_TEXT_CHARACTERS,
                  })}`,
                );
              break;

            case ComponentTypes.MULTIPLE_TEXT:
              const { firstTitle, secondTitle } = element;

              sectionSchema[fieldId] = yup
                .array()
                .required()
                .of(
                  yup.object().shape({
                    field1: yup
                      .string()
                      .transform((value) => {
                        const currentValue = value?.[currentLanguage];

                        return typeof currentValue === 'string'
                          ? currentValue.trim()
                          : currentValue;
                      })
                      .required(
                        `${t(firstTitle ? 'field' : '')} ${t(
                          firstTitle ?? 'this_field',
                        )} ${t('validation.is_required')}`,
                      )
                      .min(
                        MIN_LENGTH_CHARACTERS,
                        `${t(firstTitle ?? 'this_field')} ${t(
                          'validation.min_chars',
                          {
                            num: MIN_LENGTH_CHARACTERS,
                          },
                        )}`,
                      )
                      .max(
                        MAX_LENGTH_TEXT_CHARACTERS,
                        `${t(firstTitle ?? 'this_field')} ${t(
                          'validation.max_chars',
                          {
                            num: MAX_LENGTH_TEXT_CHARACTERS,
                          },
                        )}`,
                      ),
                    field2: yup
                      .string()
                      .transform((value) => {
                        const currentValue = value?.[currentLanguage];

                        return typeof currentValue === 'string'
                          ? currentValue.trim()
                          : currentValue;
                      })
                      .required(
                        `${t(secondTitle ? 'field' : '')} ${t(
                          secondTitle ?? 'this_field',
                        )} ${t('validation.is_required')}`,
                      )
                      .min(
                        MIN_LENGTH_CHARACTERS,
                        `${t(secondTitle ?? 'this_field')} ${t(
                          'validation.min_chars',
                          {
                            num: MIN_LENGTH_CHARACTERS,
                          },
                        )}`,
                      )
                      .max(
                        MAX_LENGTH_TEXT_CHARACTERS,
                        `${t(secondTitle ?? 'this_field')} ${t(
                          'validation.max_chars',
                          {
                            num: MAX_LENGTH_TEXT_CHARACTERS,
                          },
                        )}`,
                      ),
                  }),
                );
              break;

            default:
              break;
          }
        });
      }

      schemaObject[sectionId] = yup.object().shape(sectionSchema);
    });
  }

  return yup.object().shape(schemaObject);
};
const isMultipleTextItemArray = (
  data: unknown,
): data is MultipleTextItemTemplate[] =>
  Array.isArray(data) &&
  data.every(
    (item) =>
      typeof item === 'object' &&
      item !== null &&
      typeof item.field1 === 'object' &&
      typeof item.field2 === 'object',
  );

const isPartialOverallRiskType = (
  obj: unknown,
): obj is Partial<OverallRiskType> => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const hasEffectScore =
    'effectScore' in obj &&
    (typeof (obj as { effectScore: unknown }).effectScore === 'string' ||
      typeof (obj as { effectScore: unknown }).effectScore === 'number');
  const hasProbabilityScore =
    'probabilityScore' in obj &&
    (typeof (obj as { probabilityScore: unknown }).probabilityScore ===
      'string' ||
      typeof (obj as { probabilityScore: unknown }).probabilityScore ===
        'number');
  const hasNewOverallRisk =
    'newOverallRisk' in obj &&
    typeof (obj as { newOverallRisk: unknown }).newOverallRisk === 'number';
  const hasOverallRisk =
    'overallRisk' in obj &&
    typeof (obj as { overallRisk: unknown }).overallRisk === 'number';

  return (
    hasEffectScore || hasProbabilityScore || hasNewOverallRisk || hasOverallRisk
  );
};

const getOverallAndNewOverallRisks = (data: DefaultValues) => {
  const { effectScore: effectScore2, probabilityScore: probabilityScore2 } =
    data[OVERALL_RISK_SECTION] || {};
  const { probabilityScore: probabilityScore3 } =
    data[NEW_OVERALL_RISK_SECTION] || {};

  const overallRisk =
    Number(effectScore2) * Number(probabilityScore2) || undefined;
  const newOverallRisk =
    Number(effectScore2) * Number(probabilityScore3) || undefined;

  return { overallRisk, newOverallRisk };
};

const isSectionFilled = (sectionData: Element) =>
  Object.values(sectionData).every((value) => value !== '');

export const deserializeFormData = (
  data: DefaultValues,
  jsonTemplate: JsonTemplate,
): JsonTemplate => ({
  ...jsonTemplate,
  sections: jsonTemplate?.sections.map((section) => {
    const sectionData = data[section.id];

    if (!sectionData) return section;

    const elementsWithData = section.elements?.map((element) => {
      if (
        element.type === ComponentTypes.OVERALL_RISK &&
        isPartialOverallRiskType(sectionData)
      ) {
        const { overallRisk, newOverallRisk } =
          getOverallAndNewOverallRisks(data);

        return {
          ...element,
          effectScore: {
            ...element.effectScore,
            value: sectionData.effectScore,
          },
          probabilityScore: {
            ...element.probabilityScore,
            value: sectionData.probabilityScore,
          },
          overallRisk,
          newOverallRisk,
        };
      }

      const elementData = sectionData[element.id];

      if (elementData === undefined) return element;

      if (
        element.type === ComponentTypes.MULTIPLE_TEXT &&
        isMultipleTextItemArray(elementData)
      ) {
        const itemsWithData = elementData.map((itemData) => ({
          ...element.items?.[0],
          ...itemData,
        }));

        return { ...element, items: itemsWithData };
      }

      return { ...element, value: elementData };
    });

    const overallRisk =
      sectionData.overallRisk?.toString() || section.overallRisk?.toString();
    const newOverallRisk =
      sectionData.newOverallRisk?.toString() ||
      section.newOverallRisk?.toString();

    return {
      ...section,
      elements: elementsWithData,
      isCompleted:
        section.id === 'section1' ? isSectionFilled(sectionData) : false,
      overallRisk: section.title === titleOverallRisk ? overallRisk : undefined,
      newOverallRisk:
        section.title === titleNewOverallRisk ? newOverallRisk : undefined,
    };
  }),
});

export const deserializeSectionStates = (
  jsonTemplate: JsonTemplate,
  sectionsState: SectionStates,
): JsonTemplate => ({
  ...jsonTemplate,
  sections: jsonTemplate.sections.map((section) => ({
    ...section,
    isCollapsed: sectionsState[section.id] || false,
  })),
});

export const deserializeNodes = (
  data: DefaultValues,
  nodes: TreeNode[],
  nodeId?: string,
): TreeNode[] => {
  const findAndReplaceNode = (node: TreeNode): TreeNode => {
    if (node.id === nodeId) {
      const { overallRisk, newOverallRisk } =
        getOverallAndNewOverallRisks(data);

      return {
        ...node,
        overallRisk,
        newOverallRisk,
      };
    }

    if (node.children?.length) {
      const updatedChildren = node.children.map(findAndReplaceNode);

      return { ...node, children: updatedChildren };
    }

    return node;
  };

  return nodes.map((node) => (nodeId ? findAndReplaceNode(node) : node));
};

export const getProcessTitle = (processes?: Process[], processId?: string) => {
  return `processes.${
    processes?.find((process) => process._id === processId)?.name ??
    'sewage_treatment'
  }`;
};

export const findNodeById = (nodes: Node[], id: string): Node | null => {
  let foundNode: Node | null = null;

  nodes.forEach((node) => {
    if (node.id === id) {
      foundNode = node;
    } else if (node.children) {
      const found = findNodeById(node.children, id);

      if (found) {
        foundNode = found;
      }
    }
  });

  return foundNode;
};

export const formatValueToCurrency = (value: number) =>
  !value ? 0 : new Intl.NumberFormat('en-US').format(value);

export const cloneAndUncollapseNodes = (nodes: Node[]) => {
  const clonedAndUncollapsedNodes: Node[] = [];

  for (const node of nodes) {
    clonedAndUncollapsedNodes.push({
      ...node,
      isCollapsed: false,
      children: !node.children ? [] : cloneAndUncollapseNodes(node.children),
    });
  }

  return clonedAndUncollapsedNodes;
};
