import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { AdminCreateOrganizationPage } from 'pages/admin-create-organization-page/admin-create-organization-page';
import { AdminOrganizationDetailsPage } from 'pages/admin-organization-details-page/admin-organization-details-page';
import { AdminPage } from 'pages/admin-page/admin-page';
import { DetailedAssessmentPage } from 'pages/detailed-assessment-page/detailed-assessment-page';
import { DecompositionPanel } from 'pages/detailed-assessment-page/tab-panels/decomposition-panel/decomposition-panel';
import PotentialFailuresPanelWrapper from 'pages/detailed-assessment-page/tab-panels/potential-failures-panel/potential-failures-panel-wrapper';
import { RecommendedPriorityPanel } from 'pages/detailed-assessment-page/tab-panels/recommended-priority-panel/recommended-priority-panel';
import RiskMatrixPanelWrapper from 'pages/detailed-assessment-page/tab-panels/risk-matrix-panel/risk-matrix-panel-wrapper';
import { ErrorPage } from 'pages/error-page/error-page';
import { GeneralInformationPage } from 'pages/general-information-page/general-information-page';
import { ProcessesPage } from 'pages/processes-page/processes-page';

import { Auth0ProviderWithNavigate } from 'components/auth0-provider-with-navigate/auth0-provider-with-navigate';
import { AuthenticationGuard } from 'components/authentication-guard/authentication-guard';
import { Layout } from 'components/layout/layout';
import { NavigatorByRole } from 'components/navigator-by-role/navigator-by-role';
import { NotAdminRoleGuard } from 'components/not-admin-role-guard/not-admin-role-guard';

import { DetailedAssessmentRoutes, Routes } from './constants';

const Auth0ProviderLayout = () => (
  <Auth0ProviderWithNavigate>
    <Outlet />
  </Auth0ProviderWithNavigate>
);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderLayout />}>
      <Route
        element={<AuthenticationGuard component={() => <Layout />} />}
        errorElement={<ErrorPage />}
      >
        <Route element={<NavigatorByRole />} path="/" />

        <Route
          element={<ProcessesPage />}
          path={`${Routes.ORGANIZATIONS}/:organizationId`}
        />

        <Route
          element={
            <NotAdminRoleGuard>
              <AdminPage />
            </NotAdminRoleGuard>
          }
          path={`${Routes.ADMIN}/${Routes.ORGANIZATIONS}`}
        />

        <Route
          element={
            <NotAdminRoleGuard>
              <AdminCreateOrganizationPage />
            </NotAdminRoleGuard>
          }
          path={`${Routes.ADMIN}/${Routes.ORGANIZATIONS}/new`}
        />

        <Route
          element={
            <NotAdminRoleGuard>
              <AdminOrganizationDetailsPage />
            </NotAdminRoleGuard>
          }
          path={`${Routes.ADMIN}/${Routes.ORGANIZATIONS}/:organizationId`}
        />

        <Route
          element={<GeneralInformationPage />}
          path={`${Routes.ORGANIZATIONS}/:organizationId/${Routes.PROCESSES}/:processId/${Routes.STEPS}/:stepId/${Routes.GENERAL_INFORMATION_SURVEY}`}
        />

        <Route
          element={<Navigate to={DetailedAssessmentRoutes.DECOMPOSITION} />}
          path={`${Routes.ORGANIZATIONS}/:organizationId/${Routes.PROCESSES}/:processId/${Routes.STEPS}/:stepId/${Routes.DETAILED_ASSESSMENT_SURVEY}`}
        />

        <Route
          element={<DetailedAssessmentPage />}
          path={`${Routes.ORGANIZATIONS}/:organizationId/${Routes.PROCESSES}/:processId/${Routes.STEPS}/:stepId/${Routes.DETAILED_ASSESSMENT_SURVEY}`}
        >
          <Route
            path={DetailedAssessmentRoutes.DECOMPOSITION}
            element={<DecompositionPanel />}
          />

          <Route
            path={DetailedAssessmentRoutes.RISK_MATRIX}
            element={<RiskMatrixPanelWrapper />}
          />

          <Route
            path={DetailedAssessmentRoutes.POTENTIAL_FAILURES}
            element={<PotentialFailuresPanelWrapper />}
          />

          <Route
            path={DetailedAssessmentRoutes.RECOMMENDED_PRIORITY}
            element={<RecommendedPriorityPanel />}
          />
        </Route>
      </Route>
    </Route>,
  ),
);
