import { styled } from '@mui/material/styles';

export const RiskMatrixPanelLayout = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  padding: 32px 32px 64px;
`;

export const RiskMatrixBlock = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
