import { Box, Typography, styled } from '@mui/material';

import { theme } from 'configs/theme';

import { StyledProcessStepWrapperProps } from './types';

export const StyledTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 700,
  maxWidth: 180,
  lineHeight: '24px',
});

export const StyledClickableBlock = styled(Box)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled ? theme.palette.text.disabled : 'inherit',
    opacity: disabled ? 0.5 : 1,
  }),
);

export const StyledDownloadReportBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const StyledClickableTypography = styled(Typography)({
  margin: '0 8px',
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.primary.main,
});

export const StyledProcessStepWrapper = styled(
  Box,
)<StyledProcessStepWrapperProps>(({ isLastStep }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 290,
  padding: 32,
  borderRadius: isLastStep ? '0 8px 8px 0' : '8px 0 0 8px',
  backgroundColor: theme.palette.common.white,
  borderRight: isLastStep ? 'unset' : `1px solid ${theme.palette.grey[300]}`,
}));
