import { DEFAULT_RISK_SCORE_VALUES } from 'hooks/queries/risk-score/constants';
import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import {
  NOT_ASSESSED_OVERALL_RISK_VALUE,
  OverallScoreResultEnum,
} from '../constants';
import { StyledOverallRiskWrapper } from './styles';
import { RiskDefinitionProps } from './types';

export const RiskDefinition = memo(
  ({ sectionId, isNewOverallRisk, riskScores }: RiskDefinitionProps) => {
    const { t } = useTranslation();
    const { getValues, setValue } = useFormContext();
    const formValues = getValues();

    const formSectionValues = formValues[sectionId];
    const { effectScore, probabilityScore } = formSectionValues;

    const {
      effectScore: overallRiskEffectScore,
      probabilityScore: overallRiskProbabilityScore,
    } = formValues.section2;

    const [result, setResult] = useState<string | number>(
      NOT_ASSESSED_OVERALL_RISK_VALUE,
    );

    useEffect(() => {
      const overallScoreResult = isNewOverallRisk
        ? OverallScoreResultEnum.NEW_OVERALL_RISK
        : OverallScoreResultEnum.OVERALL_RISK;

      let newResult: string | number = NOT_ASSESSED_OVERALL_RISK_VALUE;

      if (isNewOverallRisk) {
        const isScoresValid =
          overallRiskEffectScore !== NOT_ASSESSED_OVERALL_RISK_VALUE &&
          overallRiskProbabilityScore !== NOT_ASSESSED_OVERALL_RISK_VALUE &&
          probabilityScore !== NOT_ASSESSED_OVERALL_RISK_VALUE;

        if (isScoresValid) {
          newResult = overallRiskEffectScore * probabilityScore;
        } else if (probabilityScore === NOT_ASSESSED_OVERALL_RISK_VALUE) {
          newResult = NOT_ASSESSED_OVERALL_RISK_VALUE;
        }
      } else {
        const isScoresValid =
          effectScore !== NOT_ASSESSED_OVERALL_RISK_VALUE &&
          probabilityScore !== NOT_ASSESSED_OVERALL_RISK_VALUE;

        if (isScoresValid) {
          newResult = effectScore * probabilityScore;
        }
      }
      setResult(newResult);
      setValue(`${sectionId}.${overallScoreResult}`, newResult);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    const riskScore =
      riskScores?.[result] ??
      DEFAULT_RISK_SCORE_VALUES[NOT_ASSESSED_OVERALL_RISK_VALUE];

    const { color, type, description } = riskScore;

    return (
      <StyledOverallRiskWrapper borderColor={riskScore.color}>
        <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
          {t(
            `failures_and_remedy.${
              isNewOverallRisk ? 'new_overall_risk' : 'overall_risk'
            }`,
          )}
        </Typography>

        {result && color && type && (
          <Typography
            color={color}
            sx={{ fontSize: 18, fontWeight: 800, mb: 1 }}
          >
            {t(type)} {typeof result === 'number' && `(${result})`}
          </Typography>
        )}

        {description && (
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
            {t(description)}
          </Typography>
        )}
      </StyledOverallRiskWrapper>
    );
  },
);

RiskDefinition.displayName = 'RiskDefinition';
