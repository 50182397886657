import { theme } from 'configs/theme';

import { Box, FormControlLabel, Typography, styled } from '@mui/material';

export const StyledIndexWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  borderRadius: 4,
  minWidth: 40,
  height: 40,
  marginTop: 24,
});

export const StyledMultipleTextItemWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 16,
  margin: '40px 0',
});

export const StyledFieldsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  alignItems: 'flex-start',
  margin: 'unset',
});

export const StyledTextFieldLabel = styled(Typography)({
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 2,
});
