import { Severity } from 'context/types';
import { useGenerateReport } from 'hooks/mutations/report/report';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { Language } from 'components/language-switcher/types';

import { ReactComponent as ArrowCircleRightIcon } from 'assets/arrow-circle-right-icon.svg';
import { ReactComponent as DocumentIcon } from 'assets/document-icon.svg';
import { ReactComponent as FileDownloadIcon } from 'assets/file_download.svg';

import { AppContext } from 'context';

import {
  StyledClickableBlock,
  StyledClickableTypography,
  StyledDownloadReportBlock,
  StyledProcessStepWrapper,
  StyledTitle,
} from './styles';
import { ProcessSectionProps } from './types';

const GeneratedFileDownloadNotification: FC<{ responseLink: string }> = ({
  responseLink,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography>
        {t('download_report_language.statuses.generating_report_is_completed')}{' '}
        <a href={responseLink} target="_blank" rel="noreferrer">
          {t('download')}
        </a>
      </Typography>
    </Box>
  );
};

export const ProcessSection = ({
  organizationId,
  processId,
  section,
  sectionsLength,
}: ProcessSectionProps) => {
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  const { mutateAsync: generateReport } = useGenerateReport();

  const [state, setState] = useState({
    isDocumentGenerating: false,
    [Language.UA]: false,
    [Language.EN]: false,
  });

  const {
    [Language.UA]: isUADisabled,
    [Language.EN]: isENDisabled,
    isDocumentGenerating,
  } = state;

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isDocumentGenerating) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    [isDocumentGenerating],
  );

  const handleDownloadDocument = useCallback(
    async (language: Language) => {
      try {
        setState((prevState) => ({
          ...prevState,
          [language]: true,
          isDocumentGenerating: true,
        }));

        showNotification({
          isShowingNotification: true,
          type: Severity.Info,
          message: t('download_report_language.statuses.generating_report'),
          hideDuration: 'disabled',
        });

        const response = await generateReport({
          organizationId,
          processId,
          language,
        });

        if (response) {
          showNotification({
            isShowingNotification: true,
            type: Severity.Success,
            message: (
              <GeneratedFileDownloadNotification responseLink={response} />
            ),
            hideDuration: 'disabled',
          });

          setState((prevState) => ({
            ...prevState,
            [language]: false,
            isDocumentGenerating: false,
          }));
        }
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          [language]: false,
          isDocumentGenerating: false,
        }));

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: t('errors.report.generate_report', { error }),
        });
      }
    },
    [generateReport, organizationId, processId, showNotification, t],
  );

  useEffect(() => {
    if (isDocumentGenerating) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isDocumentGenerating, handleBeforeUnload]);

  const { sectionName, sectionIndex, isDownloadReportStep } = section;

  const stepInfoTopComponent = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ fontSize: 48, fontWeight: 600, mr: 4 }}>
        {sectionIndex}
      </Typography>

      <Box sx={{ maxWidth: 145 }}>
        <StyledTitle>{t(`sections.${sectionName}`)}</StyledTitle>
      </Box>
    </Box>
  );

  const downloadReportBlock = (
    <StyledDownloadReportBlock>
      <StyledClickableBlock
        sx={{ mb: 3 }}
        onClick={(event) => {
          event.stopPropagation();
          !isUADisabled && handleDownloadDocument(Language.UA);
        }}
        disabled={isUADisabled}
      >
        <DocumentIcon />

        <StyledClickableTypography>
          {t('download_report_language.languages.ua')}
        </StyledClickableTypography>

        <FileDownloadIcon />
      </StyledClickableBlock>

      <StyledClickableBlock
        onClick={(event) => {
          event.stopPropagation();
          !isENDisabled && handleDownloadDocument(Language.EN);
        }}
        disabled={isENDisabled}
      >
        <DocumentIcon />

        <StyledClickableTypography>
          {t('download_report_language.languages.en')}
        </StyledClickableTypography>

        <FileDownloadIcon />
      </StyledClickableBlock>
    </StyledDownloadReportBlock>
  );

  const stepInfoBottomComponent = isDownloadReportStep ? (
    downloadReportBlock
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <StyledClickableBlock>
        <ArrowCircleRightIcon />
      </StyledClickableBlock>
    </Box>
  );

  return (
    <StyledProcessStepWrapper isLastStep={sectionsLength === sectionIndex}>
      {stepInfoTopComponent}

      {stepInfoBottomComponent}
    </StyledProcessStepWrapper>
  );
};
