import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, TextField, Typography } from '@mui/material';

import {
  MAX_LENGTH_MULTILINE_TEXT_CHARACTERS,
  MIN_LENGTH_CHARACTERS,
} from 'constants/index';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { CommentTemplateComponentProps } from './types';

export const CommentTemplateComponent = ({
  elementId,
  placeholder,
  label,
  fieldName,
  description,
  styles,
  width,
  rows = 8,
  inputProps,
  onUpdateTemplate,
}: CommentTemplateComponentProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();

  const commentLabel = (
    <>
      {label && (
        <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
          {label}
        </Typography>
      )}

      {description && (
        <Typography sx={{ fontSize: 14, mb: 0.5 }}>{description}</Typography>
      )}
    </>
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field, fieldState: { error, isDirty } }) => (
        <FormControlLabel
          sx={{
            alignItems: 'flex-start',
            mx: 'unset',
            width: width ?? '100%',
            ...styles,
          }}
          control={
            isEnLanguage ? (
              <Typography sx={{ marginBottom: 0 }}>
                {field?.value?.[currentLanguage] || t('empty_field')}
              </Typography>
            ) : (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder}
                fullWidth
                multiline
                rows={rows}
                inputProps={{
                  ...inputProps,
                  readOnly: isEnLanguage,
                  disabled: isEnLanguage,
                  maxLength: MAX_LENGTH_MULTILINE_TEXT_CHARACTERS,
                  minLength: MIN_LENGTH_CHARACTERS,
                }}
                value={field?.value?.[currentLanguage]}
                onChange={(e) => {
                  const updatedValue = {
                    ...field.value,
                    [currentLanguage]: e.target.value,
                  };

                  field.onChange(updatedValue);
                }}
                onBlur={(e) => {
                  const updatedValue = {
                    ...field.value,
                    [currentLanguage]: e.target.value.trim(),
                  };

                  field.onChange(updatedValue);
                  if (isDirty) {
                    onUpdateTemplate?.(elementId);
                  }
                }}
              />
            )
          }
          label={commentLabel}
          labelPlacement="top"
        />
      )}
    />
  );
};
