import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu as MenuComponent, MenuItem } from '@mui/material';

import { MenuProps } from './types';

export const Menu: FC<MenuProps> = ({
  options,
  anchorEl,
  open,
  onClose,
  onOptionClick,
}) => {
  const { t } = useTranslation();

  return (
    <MenuComponent anchorEl={anchorEl} open={!!open} onClose={onClose}>
      {options?.map((option) => (
        <MenuItem key={option} onClick={() => onOptionClick?.(option)}>
          {t(option)}
        </MenuItem>
      ))}
    </MenuComponent>
  );
};
