import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from 'routes/constants';

import { useAuth0 } from '@auth0/auth0-react';

import { PageLoader } from 'components/page-loader/page-loader';

import { UserModel } from 'models/user.model';

export const NavigatorByRole: FC = () => {
  const { isLoading, user } = useAuth0<UserModel>();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Navigate
      to={
        user?.app_metadata.isAdmin
          ? `${Routes.ADMIN}/${Routes.ORGANIZATIONS}`
          : `${Routes.ORGANIZATIONS}/${user?.app_metadata.orgId}`
      }
    />
  );
};
