import { RiskScores } from 'models/risk-score.model';

export const DEFAULT_RISK_SCORE_VALUES: RiskScores = {
  not_assessed: {
    color: '#697586',
    description: 'risk_definitions.not_assessed_description',
    type: 'risk_definitions.not_assessed',
    _id: '65df3b42efe840759ba5e615',
  },
};
