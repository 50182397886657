import { FC, memo } from 'react';

import { NEW_OVERALL_RISK_SECTION, ScoreEnum } from '../constants';
import { DropdownTemplateComponent } from '../dropdown-template/dropdown-template';
import { RiskDefinition } from '../risk-definition/risk-definition';
import { OverallRiskTemplateProps } from './types';

export const OverallRiskComponentTemplate: FC<OverallRiskTemplateProps> = memo(
  ({ element, sectionId, riskScores, onUpdateTemplate }) => (
    <>
      <DropdownTemplateComponent
        fieldName={`${sectionId}.${ScoreEnum.EFFECT_SCORE}`}
        element={element.effectScore}
        onUpdateTemplate={onUpdateTemplate}
      />

      <DropdownTemplateComponent
        fieldName={`${sectionId}.${ScoreEnum.PROBABILITY_SCORE}`}
        element={element.probabilityScore}
        onUpdateTemplate={onUpdateTemplate}
      />

      <RiskDefinition
        sectionId={sectionId}
        isNewOverallRisk={sectionId === NEW_OVERALL_RISK_SECTION}
        riskScores={riskScores}
      />
    </>
  ),
);

OverallRiskComponentTemplate.displayName = 'OverallRiskComponentTemplate';
