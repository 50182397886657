import { useUpdateCompositionData } from 'hooks/mutations/composition/composition';
import { useUpdatePotentialFailuresTemplate } from 'hooks/mutations/potential-failures-template/potential-failures-template';
import { FC, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';

import { PageLoader } from 'components/page-loader/page-loader';
import { TreeNodesStepper } from 'components/tree-nodes-stepper/tree-nodes-stepper/tree-nodes-stepper';

import { SectionStates, TemplateTypes } from 'models/template.model';

import {
  deserializeFormData,
  deserializeNodes,
  deserializeSectionStates,
  findNodeById,
} from 'utils';

import { useGetTemplateId } from 'hooks/hooks/useGetTemplateId';
import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';
import { useUserResponseId } from 'hooks/hooks/useUserResponseId';

import PotentialFailureForm from './potential-failure-form';
import { StyledStickyGridItem } from './styles';
import { PotentialFailuresPanelProps } from './types';

export const PotentialFailuresPanel: FC<PotentialFailuresPanelProps> = ({
  templateData,
  treeNodes,
  riskScores,
  selectedTemplateId,
  onSelectTemplateId,
  isLoadingTemplateData,
  isLoadingRiskScores,
}) => {
  const { currentLanguage } = useIsEnLanguage();
  const { sections } = templateData || {};
  const [selectedTemplateTitle, setSelectedTemplateTitle] = useState('');
  const [sectionStates, setSectionStates] = useState<SectionStates>({});

  const { processId, organizationId, stepId } = useParams();
  const { setState: setUserResponseId } = useUserResponseId();
  const templateId = useGetTemplateId(
    TemplateTypes.DECOMPOSITION,
    organizationId,
    processId,
    stepId,
  );

  const { mutateAsync: updateCompositionData } = useUpdateCompositionData();

  const { mutateAsync: updatePotentialFailuresTemplate } =
    useUpdatePotentialFailuresTemplate();

  useEffect(() => {
    setUserResponseId(selectedTemplateId);
  }, [selectedTemplateId, setUserResponseId]);

  useEffect(() => {
    if (treeNodes.length > 0) {
      const firstNode = treeNodes[0];

      if (firstNode) {
        setSelectedTemplateTitle(firstNode.title[currentLanguage] ?? '');
        firstNode.id && onSelectTemplateId(firstNode.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFailuresRelatedData = async (
    values: FieldValues,
    mutatedElementId?: string,
  ) => {
    if (templateData) {
      const deserializedFormData = deserializeFormData(values, templateData);
      const deserializedNodes = deserializeNodes(
        values,
        treeNodes,
        selectedTemplateId,
      );

      await updatePotentialFailuresTemplate({
        data: {
          response: deserializedFormData,
          mutatedElementId,
          templateName: 'Potential Failures',
        },
        templateId: selectedTemplateId,
        references: {
          organizationId,
          processId,
        },
      });

      await updateCompositionData({
        data: {
          response: deserializedNodes,
          mutatedElementId: selectedTemplateId,
          templateName: 'Decomposition',
        },
        organizationId,
        processId,
        templateId,
      });
    }
  };

  const handleCollapsibleSection = (sectionId: string) => {
    if (templateData) {
      setSectionStates((prevState) => {
        const updatedState = {
          ...prevState,
          [sectionId]: !prevState[sectionId],
        };

        const serializedSectionStates = deserializeSectionStates(
          templateData,
          updatedState,
        );

        updatePotentialFailuresTemplate({
          data: {
            response: serializedSectionStates,
            templateName: 'Potential Failures',
            mutatedElementId: undefined,
          },
          templateId: undefined,
          references: {
            organizationId,
            processId,
          },
        });

        return updatedState;
      });
    }
  };

  const handleSelectTemplate = (templateId: string) => {
    onSelectTemplateId(templateId);

    const foundNode = findNodeById(treeNodes, templateId);

    if (foundNode) {
      setSelectedTemplateTitle(foundNode.title[currentLanguage] ?? '');
    }
  };

  return (
    <Grid container>
      <StyledStickyGridItem item xs={4}>
        <TreeNodesStepper
          data={treeNodes}
          selectedId={selectedTemplateId ?? ''}
          riskScores={riskScores}
          onSelectedTemplateId={handleSelectTemplate}
        />
      </StyledStickyGridItem>

      <StyledStickyGridItem item xs={8} sx={{ bgcolor: 'background.default' }}>
        <Typography sx={{ fontSize: 24, fontWeight: 800, mb: 3 }}>
          {selectedTemplateTitle}
        </Typography>

        {isLoadingTemplateData || isLoadingRiskScores || !sections ? (
          <PageLoader />
        ) : (
          <PotentialFailureForm
            riskScores={riskScores}
            sections={sections}
            onFailuresTemplateUpdate={updateFailuresRelatedData}
            onCollapsibleSection={handleCollapsibleSection}
            sectionStates={sectionStates}
          />
        )}
      </StyledStickyGridItem>
    </Grid>
  );
};
