import { Auth0ContextInterface } from '@auth0/auth0-react';

import {
  RiskMatrixPayload,
  RiskMatrixTemplate,
  RiskMatrixUpdateResponse,
} from 'models/risk-matrix.model';
import { UserModel } from 'models/user.model';

import { axiosGet, axiosPut } from './external-api.service';

export const riskMatrixService = {
  getRiskMatrixData: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
    templateId: string | undefined,
  ) => {
    if (!organizationId || !processId || !templateId) return;

    return axiosGet<RiskMatrixTemplate>(
      auth,
      `user-responses/${organizationId}/${processId}/${templateId}`,
    );
  },

  updateRiskMatrixData: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
    templateId: string | undefined,
    data: RiskMatrixPayload,
  ) => {
    if (!organizationId || !processId || !templateId) return;

    return axiosPut<RiskMatrixUpdateResponse, RiskMatrixPayload>(
      auth,
      `user-responses/${organizationId}/${processId}/${templateId}`,
      data,
    );
  },
};
