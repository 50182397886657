import { useNavigate } from 'react-router-dom';

import { Box, Toolbar } from '@mui/material';

import { AccountMenu } from 'components/account-menu/account-menu';

import { ReactComponent as LogoIcon } from 'assets/cirnat-logo.svg';

import { headerHeight } from 'constants/index';

import { LanguageSwitcher } from '../language-switcher/language-switcher';
import { StyledAppBar, StyledGradientLine } from './styles';

export const Header = () => {
  const navigate = useNavigate();

  return (
    <StyledAppBar position="sticky" color="info">
      <Toolbar sx={{ height: headerHeight, padding: '0 32px' }} disableGutters>
        <Box
          sx={{ cursor: 'pointer', minWidth: 192, flexGrow: 1 }}
          onClick={() => navigate('/')}
        >
          <LogoIcon />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ pr: 1 }}>
            <LanguageSwitcher />
          </Box>

          <AccountMenu />
        </Box>
      </Toolbar>

      <StyledGradientLine />
    </StyledAppBar>
  );
};
