import { Box, CSSObject, styled } from '@mui/material';

import { ReactComponent as DoubleArrowRightIcon } from 'assets/double-arrow-right-icon.svg';

import { theme } from 'configs/theme';

const commonWrapperStyles: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
};

export const StyledCloseIcon = styled(DoubleArrowRightIcon)({
  cursor: 'pointer',
  position: 'absolute',
  left: -15,
  bottom: -15,
});

export const StyledCommentItemWrapper = styled(Box)<{
  isTextFieldFocused: boolean;
}>(({ isTextFieldFocused }) => ({
  ...commonWrapperStyles,
  height: `calc(100vh - ${isTextFieldFocused ? '355' : '245'}px)`,
  overflow: 'scroll',
}));

export const StyledOpenedCommentSidebarWrapper = styled(Box)<{
  isOpenDrawer: boolean;
}>(({ isOpenDrawer }) => ({
  ...commonWrapperStyles,
  alignItems: isOpenDrawer ? 'flex-start' : 'center',
  height: '100%',
}));

export const StyledClosedCommentSidebarWrapper = styled(Box)({
  ...commonWrapperStyles,
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  paddingTop: 16,
});

export const StyledAnswerCommentWrapper = styled(Box)({
  ...commonWrapperStyles,
  padding: '0 16px',
  position: 'sticky',
  top: 134,
  width: '100%',
});
