import {
  UpdateGeneralInformationTemplatePayload,
  UpdateGeneralInformationTemplateResponse,
} from 'hooks/mutations/general-information-template/types';

import { Auth0ContextInterface } from '@auth0/auth0-react';

import { Template } from 'models/template.model';
import { UserModel } from 'models/user.model';

import { axiosGet, axiosPut } from './external-api.service';

export const generalInformationTemplateService = {
  getGeneralInformationTemplate: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
    templateId: string | undefined,
  ) => {
    if (!organizationId || !processId || !templateId) return;

    return axiosGet<Template>(
      auth,
      `user-responses/${organizationId}/${processId}/${templateId}`,
    );
  },

  updateGeneralInformationTemplate: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    processId: string | undefined,
    templateId: string | undefined,
    data: UpdateGeneralInformationTemplatePayload,
  ) => {
    if (!organizationId || !processId || !templateId) return;

    return axiosPut<
      UpdateGeneralInformationTemplateResponse,
      UpdateGeneralInformationTemplatePayload
    >(
      auth,
      `user-responses/${organizationId}/${processId}/${templateId}`,
      data,
    );
  },
};
