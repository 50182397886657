import { useDeleteOrganization } from 'hooks/mutations/organizations/organizations';
import { useGetOrganizationById } from 'hooks/queries/organizations/organizations';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'routes/constants';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Stack } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { ConfirmationDialog } from 'components/confirmation-dialog/confirmation-dialog';
import { OrganizationInfo } from 'components/organization-info/organization-info';
import { OrganizationInfoModify } from 'components/organization-info/organization-info-modify';
import { PageLoader } from 'components/page-loader/page-loader';
import { ProcessesList } from 'components/processes/processes-list/processes-list';

import { UserModel } from 'models/user.model';

export const AdminOrganizationDetailsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth0<UserModel>();
  const { organizationId } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const { mutateAsync: deleteOrganization } = useDeleteOrganization(auth);
  const { data: organization, isLoading } =
    useGetOrganizationById(organizationId);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] =
    useState(false);

  const deleteOrganizationHandler = async () => {
    if (organizationId) {
      await deleteOrganization({ id: organizationId });
      navigate(`/${Routes.ADMIN}/organizations`);
    }
  };

  const enableEditModeHandler = () => {
    setIsEditMode(true);
  };

  const disableEditModeHandler = () => {
    setIsEditMode(false);
  };

  if (isLoading || !organization) {
    return <PageLoader />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 4,
          marginX: 'auto',
          maxWidth: '80%',
        }}
      >
        <Box sx={{ py: 3 }}>
          {isEditMode ? (
            <OrganizationInfoModify
              organization={organization}
              onCancel={disableEditModeHandler}
            />
          ) : (
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item>
                <OrganizationInfo organization={organization} />
              </Grid>

              <Grid item>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={enableEditModeHandler}
                  >
                    {t('edit')}
                  </Button>

                  <Button
                    color="error"
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={() => setIsOpenConfirmationDialog(true)}
                  >
                    {t('delete')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>

        <ProcessesList processes={organization?.processes ?? []} />
      </Box>

      <ConfirmationDialog
        title="delete_organization_confirmation"
        open={isOpenConfirmationDialog}
        onClose={() => setIsOpenConfirmationDialog(false)}
        onSubmit={deleteOrganizationHandler}
      />
    </>
  );
};
