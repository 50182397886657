import { format } from 'date-fns';
import { FC } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Box, IconButton, Typography } from '@mui/material';

import { Button } from 'components/button/button';
import { Menu } from 'components/menu/menu';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { CommentButtonActions } from '../comment-list-item/types';
import { CommentTextField } from '../comment-text-field/comment-text-field';
import { FilesPreview } from '../file-preview/file-preview';
import { DATE_FORMATS, DATE_LOCALES } from './constants';
import { StyledCommentBlockWrapper } from './styles';
import { CommentBlockProps } from './types';

export const CommentBlock: FC<CommentBlockProps> = ({
  avatar,
  attachments,
  commentTextFieldProps,
  commentItemProps,
  isCurrentUser,
  onDelete,
  onDownload,
}) => {
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();

  const { isEditingMode, isCreatingMode, commentValue } = commentTextFieldProps;
  const {
    name,
    timestamp,
    message,
    options,
    anchorEl,
    openMenu,
    onSetAnchorEl,
    onOptionClick,
    onButtonClick,
  } = commentItemProps || {};

  const commentTextField =
    isEditingMode || isCreatingMode ? (
      <CommentTextField {...commentTextFieldProps} />
    ) : null;

  return (
    <Box sx={{ my: 2, display: 'flex' }}>
      {avatar && (
        <Avatar
          alt={avatar}
          src={avatar}
          sx={{ height: 24, width: 24, mt: '5px' }}
        />
      )}

      <StyledCommentBlockWrapper>
        {commentTextField ?? (
          <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {name && (
                <Typography
                  sx={{ fontSize: 14, fontWeight: 700, maxWidth: 215 }}
                >
                  {name}
                </Typography>
              )}

              {timestamp && (
                <Typography sx={{ fontSize: 10, color: 'grey.A100', ml: 1 }}>
                  {format(new Date(timestamp), DATE_FORMATS[currentLanguage], {
                    locale: DATE_LOCALES[currentLanguage],
                  })}
                </Typography>
              )}
            </Box>

            {isCurrentUser && (
              <>
                <IconButton
                  onClick={(event) =>
                    onSetAnchorEl && onSetAnchorEl(event.currentTarget)
                  }
                  size="small"
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  options={options}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={() => onSetAnchorEl && onSetAnchorEl(null)}
                  onOptionClick={onOptionClick}
                />
              </>
            )}
          </Box>
        )}

        {!isEditingMode && message && (
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {isEnLanguage ? message.en : message.ua}
          </Typography>
        )}

        {attachments.length > 0 && (
          <FilesPreview
            files={attachments}
            isCurrentUser={isCurrentUser}
            isChangingMode={isEditingMode ?? isCreatingMode}
            onDelete={onDelete}
            onDownload={onDownload}
          />
        )}

        {isEditingMode && !isCreatingMode && (
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ mr: 1 }}>
              <Button
                onClick={() => onButtonClick?.(CommentButtonActions.SAVE)}
                label="save"
                variant="contained"
                size="small"
                disabled={!commentValue.trim().length}
              />
            </Box>

            <Button
              onClick={() => onButtonClick?.(CommentButtonActions.CANCEL)}
              label="cancel"
              size="small"
            />
          </Box>
        )}
      </StyledCommentBlockWrapper>
    </Box>
  );
};
