import { FC } from 'react';

import { Grid } from '@mui/material';

import { NOT_ASSESSED_OVERALL_RISK_VALUE } from 'components/overall-risk-template/constants';

import { ReactComponent as ArrowDropDownIcon } from 'assets/arrow-drop-down-icon.svg';

import { StyledRectangle } from './styles';
import { RectangleOverallRiskComponentProps } from './types';

export const RectangleOverallRiskComponent: FC<
  RectangleOverallRiskComponentProps
> = ({ newOverallRisk, overallRisk }) => {
  const renderRectangles = () =>
    Array.from({ length: 5 }, (_, i) => {
      const isActive = isRectangleActive(i + 1);

      return (
        <Grid item xs={2} key={i}>
          <StyledRectangle isActive={isActive}>
            {i + 1 === newOverallRisk && <ArrowDropDownIcon />}
          </StyledRectangle>
        </Grid>
      );
    });

  const isRectangleActive = (index: number) => {
    const isWithinOverallRiskRange =
      index >= Number(newOverallRisk) && index <= Number(overallRisk);

    const isActive =
      newOverallRisk !== NOT_ASSESSED_OVERALL_RISK_VALUE &&
      overallRisk !== NOT_ASSESSED_OVERALL_RISK_VALUE &&
      isWithinOverallRiskRange;

    return isActive;
  };

  return (
    <Grid container spacing={0.3}>
      {renderRectangles()}
    </Grid>
  );
};
