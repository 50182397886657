import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

export const PageLoader = () => (
  <Box
    sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress sx={{ color: '#004EEA' }} />

    <Typography sx={{ fontSize: 14, fontWeight: 600, mt: 1.5 }}>
      Loading...
    </Typography>
  </Box>
);
