import { Box, Typography, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const RiskLevelContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const RiskLevel = styled(Box)<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    width: '100%',
    height: 4,
    backgroundColor,
  }),
);

export const RiskIndex = styled(Typography)({
  fontSize: 12,
  fontWeight: 700,
  color: theme.palette.grey.A100,
});
