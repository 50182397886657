import { forwardRef } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, IconButton } from '@mui/material';

import { DropComponent } from 'components/drop-component/drop-component';

import { findNodeById } from 'utils';

import { ChildrenNode } from '../children-node/children-node';
import { EditableItem } from '../editable-item/editable-item';
import { CHILDREN_MAX_LENGTH } from '../tree/constants';
import { StyledLastChildrenIconWrapper } from './styles';
import { TreeNodeProps } from './types';

export const TreeNode = forwardRef<boolean, TreeNodeProps>(
  ({ node, draggableProvided, isFirstNode, setNodes }, ref) => {
    const { children, childrenCount, isCollapsed, toggleCollapse } = node;

    const hasChildren = children && children.length > 0;
    const childLastIndex = childrenCount ?? 0;

    const onDragEnd = (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      if (ref && typeof ref !== 'function') {
        ref.current = true;
      }

      setNodes((prevNodes) => {
        const updatedNodes = [...prevNodes];
        const sourceNode = findNodeById(updatedNodes, source.droppableId);
        const destinationNode = findNodeById(
          updatedNodes,
          destination.droppableId,
        );

        if (
          sourceNode &&
          sourceNode.children &&
          destinationNode &&
          destinationNode.children
        ) {
          const sourceIndex = source.index;
          const destinationIndex = destination.index;

          const [removed] = sourceNode.children.splice(sourceIndex, 1);

          destinationNode.children.splice(destinationIndex, 0, removed);
        }

        return updatedNodes;
      });
    };

    return (
      <Box>
        <Box sx={{ display: 'flex', mb: 3, alignItems: 'center' }}>
          <IconButton sx={{ mr: 1.5 }} {...draggableProvided.dragHandleProps}>
            <DragIndicatorIcon />
          </IconButton>

          {childLastIndex !== CHILDREN_MAX_LENGTH ? (
            <IconButton
              onClick={() => toggleCollapse && toggleCollapse()}
              sx={{
                bgcolor: 'background.default',
                borderRadius: '4px',
                mr: 1.5,
              }}
            >
              {isCollapsed ? <ChevronRightIcon /> : <ExpandMoreIcon />}
            </IconButton>
          ) : (
            <StyledLastChildrenIconWrapper>
              <FiberManualRecordIcon
                sx={{ width: 10, height: 10, color: 'grey.400' }}
              />
            </StyledLastChildrenIconWrapper>
          )}

          <EditableItem node={node} childLastIndex={childLastIndex} />
        </Box>

        {!isCollapsed && hasChildren && (
          <DragDropContext onDragEnd={onDragEnd}>
            <DropComponent
              droppableId={node.id || ''}
              type="droppable-tree-node"
            >
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  <ChildrenNode
                    ref={ref}
                    nodes={children}
                    isFirstNode={isFirstNode}
                    setNodes={setNodes}
                  />
                </Box>
              )}
            </DropComponent>
          </DragDropContext>
        )}
      </Box>
    );
  },
);

TreeNode.displayName = 'TreeNode';
