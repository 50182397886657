import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import {
  MAIN_ISSUES_FIELDS_INITIAL_STATE,
  MAIN_ISSUES_FIELDS_MIN_COUNT,
} from '../constants';
import { MultipleTextItem } from '../multiple-text-item/multiple-text-item';
import { MultipleTextListProps } from './types';

export const MultipleTextList = ({
  element,
  sectionId,
  id,
  onUpdateTemplate,
}: MultipleTextListProps) => {
  const { t } = useTranslation();
  const { isEnLanguage } = useIsEnLanguage();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${sectionId}.${id}`,
  });

  const fieldName = `${sectionId}.${id}`;

  return (
    <>
      {fields.map((field, index) => (
        <MultipleTextItem
          key={field.id}
          fieldName={fieldName}
          element={element}
          index={index}
          showDivider={index !== fields.length - 1}
          showDeleteButton={
            fields.length !== MAIN_ISSUES_FIELDS_MIN_COUNT && !isEnLanguage
          }
          removeField={(field) => {
            remove(field);
            onUpdateTemplate?.();
          }}
          onUpdateTemplate={onUpdateTemplate}
        />
      ))}

      {!isEnLanguage && (
        <Button
          variant="outlined"
          onClick={() => {
            append(MAIN_ISSUES_FIELDS_INITIAL_STATE);
            onUpdateTemplate?.();
          }}
          startIcon={<AddRoundedIcon />}
        >
          {t('add_issue')}
        </Button>
      )}
    </>
  );
};
