import { createTheme } from '@mui/material';

interface Shadow {
  small: string;
  medium: string;
  large: string;
  xLarge: string;
  xxLarge: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    graymodern: { 25: string };
  }

  interface PaletteOptions {
    graymodern: { 25: string };
  }

  interface Theme {
    shadow: Shadow;
  }

  interface ThemeOptions {
    shadow: Shadow;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Nunito Sans', 'Montserrat', 'sans-serif'].join(','),
    button: { textTransform: 'none' },
  },
  palette: {
    primary: {
      main: '#004EEA',
      light: 'rgba(0, 78, 234, 0.1)',
    },
    secondary: {
      main: '#292929',
      contrastText: '#141414',
    },
    grey: {
      50: '#FCFCFC',
      100: '#EEF2F6',
      300: '#CDD5DF',
      400: '#A3A3A3',
      500: '#737373',
      600: '#4B5565',
      800: '#292929',
      900: '#141414',
      A100: '#697586',
    },
    graymodern: { 25: '#FCFCFD' },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    success: { main: '#12B669' },
    background: { default: '#F8FAFC' },
  },
  shadow: {
    small:
      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    medium:
      '0px 4px 6px -2px rgba(16, 24, 40, 0.03),  0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    large: '0px 35px 60px rgba(0, 0, 0, 0.25)',
    xLarge: '20px 55px 60px rgba(0, 0, 0, 0.25)',
    xxLarge: '10px 15px 60px rgba(0, 0, 0, 0.25)',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          fontWeight: 600,
          padding: '12px 24px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
  },
});
