import { useGetCompositionData } from 'hooks/queries/composition/composition';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { PageLoader } from 'components/page-loader/page-loader';
import { Tree } from 'components/tree/tree/tree';

import { TemplateTypes } from 'models/template.model';

import { useGetTemplateId } from 'hooks/hooks/useGetTemplateId';
import { useUserResponseId } from 'hooks/hooks/useUserResponseId';

import { DecompositionPanelWrapper } from './styles';

export const DecompositionPanel: FC = () => {
  const { t } = useTranslation();
  const { setState: setUserResponseId } = useUserResponseId();
  const { processId, organizationId, stepId } = useParams();
  const templateId = useGetTemplateId(
    TemplateTypes.DECOMPOSITION,
    organizationId,
    processId,
    stepId,
  );
  const {
    id: userResponseId,
    data: nodes,
    isLoading: isLoadingNodes,
  } = useGetCompositionData(organizationId, processId, templateId);

  useEffect(() => {
    if (!isLoadingNodes) {
      setUserResponseId(userResponseId);
    }
  }, [isLoadingNodes, userResponseId, setUserResponseId]);

  if (isLoadingNodes) {
    return <PageLoader />;
  }

  return (
    <DecompositionPanelWrapper>
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ fontWeight: 800 }}>
          {t('decomposition.title')}
        </Typography>
        <Typography>{t('decomposition.description')}</Typography>
      </Box>

      <Tree
        data={nodes}
        organizationId={organizationId}
        processId={processId}
        templateId={templateId}
      />
    </DecompositionPanelWrapper>
  );
};
