import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ScoreListItem } from 'components/scores-list';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import {
  StyledLegend,
  StyledLegendItem,
  StyledLegendItemLabel,
  StyledLegendItemSquare,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  colorTableWithoutOpacity,
} from './styles';
import { generateMatrix } from './utils';

interface MatrixProps {
  rows: ScoreListItem[];
  cols: ScoreListItem[];
}

const matrixLegend = [
  { color: colorTableWithoutOpacity[1], label: 'low' },
  { color: colorTableWithoutOpacity[4], label: 'medium' },
  { color: colorTableWithoutOpacity[8], label: 'high' },
  { color: colorTableWithoutOpacity[12], label: 'very_high' },
  { color: colorTableWithoutOpacity[16], label: 'critical' },
];

export const Matrix: FC<MatrixProps> = ({ rows, cols }) => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const [matrix, setMatrix] = useState(generateMatrix(rows, cols));

  useEffect(() => {
    setMatrix(generateMatrix(rows, cols));
  }, [rows, cols]);

  return (
    <Grid container gap={3}>
      <Grid item xs={12}>
        <TableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                {cols.map(({ score, label }, colIndex) => (
                  <StyledTableCell key={colIndex} component="th">
                    <span>{score}</span>
                    <span>{label[currentLanguage]}</span>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {matrix.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  <StyledTableCell scope="row">
                    <div>
                      <span>{rows[rowIndex]?.['score']}</span>
                      <span>{rows[rowIndex]?.['label'][currentLanguage]}</span>
                    </div>
                  </StyledTableCell>
                  {row.map((value, index) => (
                    <StyledTableCell key={index} value={value}>
                      {value}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <StyledLegend>
          {matrixLegend.map((legendItem) => (
            <StyledLegendItem key={legendItem.label}>
              <StyledLegendItemSquare
                style={{ backgroundColor: legendItem.color, opacity: 1 }}
              />
              <StyledLegendItemLabel>
                {t(`risk_definitions.${legendItem.label}`)}
              </StyledLegendItemLabel>
            </StyledLegendItem>
          ))}
        </StyledLegend>
      </Grid>
    </Grid>
  );
};
