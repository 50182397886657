import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { potentialFailuresTemplateService } from 'services/potential-failures-template.service';

import { AppContext } from 'context';

import { UseGetPotentialFailuresTemplate } from './types';

export const useGetPotentialFailuresTemplate = (
  templateId?: string,
  organizationId?: string,
  processId?: string,
): UseGetPotentialFailuresTemplate => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError } = useQuery(
    [queryKeys.getPotentialFailuresTemplate, templateId],
    () =>
      potentialFailuresTemplateService.getPotentialFailuresTemplate(
        auth,
        templateId,
        organizationId,
        processId,
      ),
    {
      enabled: !!templateId || !!organizationId || !!processId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data: data?.json,
    isLoading,
    isError,
  };
};
