export enum ScoreEnum {
  EFFECT_SCORE = 'effectScore',
  PROBABILITY_SCORE = 'probabilityScore',
}

export enum OverallScoreResultEnum {
  OVERALL_RISK = 'overallRisk',
  NEW_OVERALL_RISK = 'newOverallRisk',
}

export const OVERALL_RISK_SECTION = 'section2';

export const NEW_OVERALL_RISK_SECTION = 'section3';

export const NOT_ASSESSED_OVERALL_RISK_VALUE = 'not_assessed';

export const NEW_OVERALL_RISK_EFFECT_SCORE_FIELD_NAME = `${NEW_OVERALL_RISK_SECTION}.${ScoreEnum.EFFECT_SCORE}`;

export const NEW_OVERALL_RISK_PROBABILITY_SCORE_FIELD_NAME = `${NEW_OVERALL_RISK_SECTION}.${ScoreEnum.PROBABILITY_SCORE}`;
