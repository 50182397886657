import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

import { StyledPaperLayoutWrapperProps } from './types';

export const StyledPaperLayoutWrapper = styled(
  Box,
)<StyledPaperLayoutWrapperProps>(({ borderRadius }) => ({
  borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadow.small,
  width: '100%',
}));
