import { Auth0ContextInterface } from '@auth0/auth0-react';

import { Comment } from 'models/comment.model';
import { UserModel } from 'models/user.model';

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from './external-api.service';

export const commentService = {
  getCommentsByUserResponseId: (
    auth: Auth0ContextInterface<UserModel>,
    userResponseId: string | undefined,
  ) => {
    if (!userResponseId) return;

    return axiosGet<Comment[]>(auth, `comments/${userResponseId}`);
  },

  createCommentByUserResponseId: (
    auth: Auth0ContextInterface<UserModel>,
    userResponseId: string | undefined,
    data: Comment,
  ) => {
    if (!userResponseId) return;

    return axiosPost<Comment[], Comment>(
      auth,
      `comments/${userResponseId}`,
      data,
    );
  },

  updateCommentById: (
    auth: Auth0ContextInterface<UserModel>,
    commentId: string | undefined,
    data: Comment,
  ) => {
    if (!commentId) return;

    return axiosPut<Comment, Comment>(auth, `comments/${commentId}`, data);
  },

  deleteCommentById: (
    auth: Auth0ContextInterface<UserModel>,
    commentId: string | undefined,
  ) => axiosDelete(auth, `comments/${commentId}`),
};
