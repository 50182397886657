import { AccordionDetails, Box, Typography, styled } from '@mui/material';

import { theme } from 'configs/theme';

import { StyledAccordionDetailsProps } from './types';

export const StyledAccordionIconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  fontWeight: 700,
  borderRadius: 4,
  minWidth: 40,
  height: 40,
  marginRight: 16,
});

export const StyledAccordionTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  display: 'flex',
  alignItems: 'center',
});

export const StyledAccordionDetails = styled(
  AccordionDetails,
)<StyledAccordionDetailsProps>(({ isSectionCollapsed }) => ({
  padding: 'unset',
  borderTop: !isSectionCollapsed
    ? `1px solid ${theme.palette.grey[300]}`
    : 'unset',
}));
