import { styled } from '@mui/material';

export const DescriptionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  column-gap: 4;
`;

export const DescriptionTitle = styled('h6')`
  font-size: 16px;
  margin: 0;
  font-weight: 800;
`;
