import { Node } from 'models/composition.model';

import { TreeNode } from './tree-nodes-stepper/types';

export const convertToTreeNodes = (nodes?: Node[]): TreeNode[] =>
  (nodes ?? []).map(
    ({
      id,
      title,
      childrenCount,
      children,
      overallRisk,
      newOverallRisk,
      isCollapsed,
    }) => ({
      id: id ?? '',
      title,
      childrenCount,
      children: children?.map(() => convertToTreeNodes(children))[0] ?? [],
      overallRisk,
      newOverallRisk,
      isCollapsed,
    }),
  );
