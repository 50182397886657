import { useGetRecommendedPriority } from 'hooks/queries/recommended-priority/recommended-priority';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

import { PageLoader } from 'components/page-loader/page-loader';
import { PaperLayout } from 'components/paper-layout/paper-layout';
import { RecommendedPriorityHeader } from 'components/recommended-priority-table/recommended-priority-header/recommended-priority-header';
import { RecommendedPriorityRow } from 'components/recommended-priority-table/recommended-priority-row/recommended-priority-row';

import { RecommendedPriorityDataSortBy } from 'models/recommended-priority.model';

import { useFilterAndSortRecommendedPriorityData } from 'hooks/hooks/useFilterAndSortRecommendedPriorityData';
import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';
import { useUserResponseId } from 'hooks/hooks/useUserResponseId';

import {
  RecommendedPriorityFiltersWrapper,
  RecommendedPriorityPanelWrapper,
} from './styles';

export const RecommendedPriorityPanel: FC = () => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { setState: setUserResponseId } = useUserResponseId();
  const { organizationId, processId } = useParams();
  const {
    data: recommendedPriorityData,
    isLoading: isLoadingRecommendedPriorityData,
  } = useGetRecommendedPriority(organizationId, processId);

  const {
    _id: userResponseId,
    levels: nestedLevels,
    results: recommendedPriorityPanelData,
  } = recommendedPriorityData ?? {};

  const [sortBy, setSortBy] = useState(
    RecommendedPriorityDataSortBy.DECOMPOSITION,
  );

  const [filterBy, setFilterBy] = useState<number[] | ['all']>([1, 2]);

  const filteredAndSortedRecommendedPriorityPanelData =
    useFilterAndSortRecommendedPriorityData(
      recommendedPriorityPanelData ?? [],
      sortBy,
      filterBy,
    );

  useEffect(() => {
    if (!isLoadingRecommendedPriorityData) {
      setUserResponseId(userResponseId);
    }
  }, [isLoadingRecommendedPriorityData, userResponseId, setUserResponseId]);

  if (isLoadingRecommendedPriorityData || !recommendedPriorityPanelData) {
    return <PageLoader />;
  }

  return (
    <RecommendedPriorityPanelWrapper>
      <RecommendedPriorityFiltersWrapper>
        {nestedLevels && nestedLevels.length > 0 && (
          <FormControl>
            <InputLabel id="filter-by-label">
              {t('recommended_priority.filter_by_label')}
            </InputLabel>
            <Select
              labelId="filter-by-label"
              multiple
              value={filterBy}
              input={
                <OutlinedInput
                  label={t('recommended_priority.filter_by_label')}
                />
              }
              onChange={(event) => {
                setFilterBy(
                  event.target.value[event.target.value.length - 1] === 'all'
                    ? (['all'] as ['all'])
                    : ((event.target.value as number[]).filter(
                        (elem) => (elem as unknown as string) !== 'all',
                      ) as number[]),
                );
              }}
              sx={{ minWidth: 200 }}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem value="all">{t('all')}</MenuItem>
              {nestedLevels.map((level) => (
                <MenuItem key={level} value={level}>
                  {t('level')} {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <InputLabel id="sort-by-label">
            {t('recommended_priority.sort_by_label')}
          </InputLabel>
          <Select
            labelId="sort-by-label"
            value={sortBy}
            onChange={(event) =>
              setSortBy(event.target.value as RecommendedPriorityDataSortBy)
            }
            sx={{ minWidth: 200 }}
            IconComponent={ExpandMoreIcon}
            input={
              <OutlinedInput label={t('recommended_priority.sort_by_label')} />
            }
          >
            <MenuItem value={RecommendedPriorityDataSortBy.DECOMPOSITION}>
              {t('recommended_priority.sort_by.decomposition')}
            </MenuItem>
            <MenuItem value={RecommendedPriorityDataSortBy.PRIORITY}>
              {t('recommended_priority.sort_by.priority')}
            </MenuItem>
            <MenuItem value={RecommendedPriorityDataSortBy.COST}>
              {t('recommended_priority.sort_by.cost')}
            </MenuItem>
          </Select>
        </FormControl>
      </RecommendedPriorityFiltersWrapper>
      <PaperLayout borderRadius={8} styles={{ p: 3 }}>
        <RecommendedPriorityHeader />

        {filteredAndSortedRecommendedPriorityPanelData.map((row, index) => {
          const isLastRow = index === recommendedPriorityPanelData.length - 1;

          return (
            <RecommendedPriorityRow
              key={row.id + index}
              row={row}
              isLastRow={isLastRow}
              currentLanguage={currentLanguage}
            />
          );
        })}
      </PaperLayout>
    </RecommendedPriorityPanelWrapper>
  );
};
