import { useGetRiskMatrixData } from 'hooks/queries/risk-matrix/risk-matrix';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import { TemplateTypes } from 'models/template.model';

import { useGetTemplateId } from 'hooks/hooks/useGetTemplateId';
import { useUserResponseId } from 'hooks/hooks/useUserResponseId';

import { RiskMatrixPanel } from './risk-matrix-panel';
import { RiskMatrixPanelLayout } from './styles';

const RiskMatrixPanelWrapper = () => {
  const { processId, organizationId, stepId } = useParams();
  const { setState: setUserResponseId } = useUserResponseId();
  const templateId = useGetTemplateId(
    TemplateTypes.RISK_MATRIX,
    organizationId,
    processId,
    stepId,
  );
  const {
    id: userResponseId,
    data: riskMatrix,
    isLoading: isLoadingMatrix,
  } = useGetRiskMatrixData(organizationId, processId, templateId);

  useEffect(() => {
    if (!isLoadingMatrix) {
      setUserResponseId(userResponseId);
    }
  }, [isLoadingMatrix, userResponseId, setUserResponseId]);

  if (isLoadingMatrix || !riskMatrix) {
    return;
  }

  return (
    <RiskMatrixPanelLayout>
      {isLoadingMatrix ? (
        <CircularProgress />
      ) : (
        <RiskMatrixPanel
          effectScoresValues={riskMatrix.effectScores}
          probabilityScoresValues={riskMatrix.probabilityScores}
        />
      )}
    </RiskMatrixPanelLayout>
  );
};

export default RiskMatrixPanelWrapper;
