import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledBoxContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: 81,
  padding: '32px 0',
  zIndex: 2,
  outline: '2px solid #F7FAFC',
  backgroundColor: theme.palette.background.default,
});
