import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { RectangleOverallRiskComponent } from 'components/rectangle-overall-risk-component/rectangle-overall-risk-component';

import { formatValueToCurrency } from 'utils';

import { RecommendedPriorityRowProps } from './types';

export const RecommendedPriorityRow: FC<RecommendedPriorityRowProps> = ({
  row,
  isLastRow,
  currentLanguage,
}) => {
  const {
    name,
    parentNames,
    initialOverallRiskLevel,
    newOverallRiskLevel,
    amount,
  } = row;

  const parentNamesForRender =
    parentNames.length > 0 && typeof parentNames[0] === 'string'
      ? parentNames
      : parentNames.map(
          (parentName) =>
            (parentName as { en: string; ua: string })[currentLanguage],
        );

  return (
    <Grid
      container
      sx={{ mb: isLastRow ? 0 : 2, minHeight: 49, alignItems: 'center' }}
      spacing={0.5}
    >
      <Grid item xs={4}>
        <Typography sx={{ fontWeight: 600 }}>
          {typeof name === 'object' ? name[currentLanguage] : name}
        </Typography>

        {parentNames.length > 0 && (
          <Typography sx={{ fontSize: 14, color: 'grey.A100' }}>
            ({parentNamesForRender.join(', ')})
          </Typography>
        )}
      </Grid>

      <Grid item xs={6}>
        <RectangleOverallRiskComponent
          newOverallRisk={newOverallRiskLevel}
          overallRisk={initialOverallRiskLevel}
        />
      </Grid>

      <Grid item xs={2}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, textAlign: 'right' }}>
          {formatValueToCurrency(amount)}
        </Typography>
      </Grid>
    </Grid>
  );
};
