import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { potentialFailuresTemplateService } from 'services/potential-failures-template.service';

import { AppContext } from 'context';

import { UseUpdatePotentialFailuresTemplate } from './types';

export const useUpdatePotentialFailuresTemplate = () => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({
      data,
      templateId,
      references,
    }: UseUpdatePotentialFailuresTemplate) =>
      await potentialFailuresTemplateService.updatePotentialFailuresTemplate(
        auth,
        templateId,
        data,
        references,
      ),
    {
      mutationKey: queryKeys.updatePotentialFailuresTemplate,
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.getPotentialFailuresTemplate, data?.uiId],
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
