import { Severity } from 'context/types';
import { getTime } from 'date-fns';
import {
  useDeleteAttachmentById,
  useGetAttachmentUrl,
  useUploadAttachments,
} from 'hooks/mutations/attachments/attachments';
import { useCreateCommentByUserResponseId } from 'hooks/mutations/comments/comments';
import { useGetCommentsByUserResponseId } from 'hooks/queries/comments/comments';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { FILE_NAME } from 'components/file-upload/constants';
import { PageLoader } from 'components/page-loader/page-loader';

import { Attachment } from 'models/attachments.model';
import { Comment } from 'models/comment.model';
import { UserModel } from 'models/user.model';

import { ReactComponent as MessageIcon } from 'assets/message-square-icon.svg';

import { AppContext } from 'context';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { CommentBlock } from '../comment-block/comment-block';
import { CommentListItem } from '../comment-list-item/comment-list-item';
import { CommentTextFieldProps } from '../comment-text-field/types';
import {
  StyledAnswerCommentWrapper,
  StyledCloseIcon,
  StyledClosedCommentSidebarWrapper,
  StyledCommentItemWrapper,
  StyledOpenedCommentSidebarWrapper,
} from './styles';
import { CommentsSidebarProps } from './types';

export const CommentsSidebar: FC<CommentsSidebarProps> = ({
  isOpenedCommentsSidebar,
  onSetIsOpenedCommentsSidebar,
}) => {
  const { currentLanguage } = useIsEnLanguage();
  const { t } = useTranslation();
  const { user } = useAuth0<UserModel>();
  const {
    userResponseId: { userResponseId },
    notification: { showNotification },
  } = useContext(AppContext);

  const { data: comments, isLoading: isLoadingComments } =
    useGetCommentsByUserResponseId(userResponseId);
  const { mutateAsync: createCommentByUserResponseId } =
    useCreateCommentByUserResponseId();
  const { mutateAsync: getAttachmentUrl } = useGetAttachmentUrl();
  const { mutateAsync: deleteAttachmentById } = useDeleteAttachmentById();
  const { mutateAsync: uploadAttachments } = useUploadAttachments();

  const [commentValue, setCommentValue] = useState('');
  const [files, setFiles] = useState<Attachment[]>([]);
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const formData = new FormData();

      Array.from(files).forEach((file) => formData.append(FILE_NAME, file));

      try {
        const uploadedUrls = await uploadAttachments({ data: formData });

        setFiles((prevFiles) => [...prevFiles, ...uploadedUrls]);
      } catch (error) {
        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: t('errors.file.upload_file', { error }),
        });
      }
    }
  };

  const handleDelete = async (attachmentId: string) => {
    try {
      await deleteAttachmentById({ id: attachmentId });

      const filteredFiles = files.filter(
        ({ fileId }) => fileId !== attachmentId,
      );

      setFiles(filteredFiles);
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.file.delete_file', { error }),
      });
    }
  };

  const handleDownload = async (attachmentId: string) => {
    try {
      const downloadUrl = await getAttachmentUrl({ id: attachmentId });
      const link = document.createElement('a');

      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.file.get_file', { error }),
      });
    }
  };

  const handleCommentSend = async () => {
    if (userResponseId) {
      const commentData: Comment = {
        userResponseId,
        user: { name: user?.name, avatar: user?.picture, email: user?.email },
        timestamp: getTime(new Date()),
        message: { [currentLanguage]: commentValue },
        attachments: files,
      };

      setFiles([]);

      await createCommentByUserResponseId({
        userResponseId,
        data: commentData,
      });
    }
  };

  useEffect(() => {
    setFiles([]);
    setCommentValue('');
    setIsTextFieldFocused(false);
  }, [userResponseId]);

  const commentTextFieldProps: CommentTextFieldProps = {
    isCreatingMode: true,
    isTextFieldFocused,
    commentValue,
    onSetIsTextFieldFocused: setIsTextFieldFocused,
    onFileChange: handleFileChange,
    onSetCommentValue: setCommentValue,
    onCommentSend: handleCommentSend,
  };

  return (
    <>
      {!isOpenedCommentsSidebar && (
        <StyledClosedCommentSidebarWrapper>
          <IconButton
            size="large"
            sx={{ position: 'sticky', top: 100 }}
            onClick={() =>
              onSetIsOpenedCommentsSidebar(!isOpenedCommentsSidebar)
            }
          >
            <MessageIcon />
          </IconButton>
        </StyledClosedCommentSidebarWrapper>
      )}

      {isOpenedCommentsSidebar && (
        <StyledOpenedCommentSidebarWrapper
          isOpenDrawer={isOpenedCommentsSidebar}
        >
          <Box sx={{ p: 2, position: 'sticky', top: 81, zIndex: 2 }}>
            <StyledCloseIcon
              onClick={() =>
                onSetIsOpenedCommentsSidebar(!isOpenedCommentsSidebar)
              }
            />

            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              {t('comments.title', { commentsCount: comments.length })}
            </Typography>
          </Box>

          <Divider
            sx={{ width: '100%', position: 'sticky', top: 134, zIndex: 1 }}
          />

          <StyledAnswerCommentWrapper>
            <CommentBlock
              avatar={user?.picture}
              commentTextFieldProps={commentTextFieldProps}
              isCurrentUser={true}
              attachments={files}
              onDelete={handleDelete}
              onDownload={handleDownload}
            />

            <Divider />

            <StyledCommentItemWrapper isTextFieldFocused={isTextFieldFocused}>
              {isLoadingComments && <PageLoader />}

              {!isLoadingComments && !comments.length && (
                <Typography
                  sx={{ py: 3, fontWeight: 600, textAlign: 'center' }}
                >
                  {t('comments.no_comments_yet')}
                </Typography>
              )}

              {!isLoadingComments &&
                comments.length > 0 &&
                comments.map((comment, index) => (
                  <CommentListItem
                    key={comment._id}
                    comment={comment}
                    isCurrentUser={user?.email === comment.user.email}
                    isLastComment={comments.length - 1 === index}
                  />
                ))}
            </StyledCommentItemWrapper>
          </StyledAnswerCommentWrapper>
        </StyledOpenedCommentSidebarWrapper>
      )}
    </>
  );
};
