import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { attachmentService } from 'services/attachment.service';

import { AppContext } from 'context';

import {
  DeleteAttachmentByIdOptions,
  UseGetAttachmentUrl,
  UseUseUploadAttachments,
} from './types';

export const useUploadAttachments = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ data }: UseUseUploadAttachments) =>
      await attachmentService.uploadAttachments(auth, data),
    {
      mutationKey: queryKeys.uploadAttachments,
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.uploadAttachments),
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};

export const useGetAttachmentUrl = () => {
  const auth = useAuth0<UserModel>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ id }: UseGetAttachmentUrl) =>
      await attachmentService.getAttachmentUrl(auth, id),
    {
      mutationKey: queryKeys.getAttachmentUrl,
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeys.getAttachmentUrl),
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};

export const useDeleteAttachmentById = () => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async ({ id }: DeleteAttachmentByIdOptions) =>
      await attachmentService.deleteAttachmentById(auth, id),
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};
