import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

export const ErrorPage = () => {
  const { t } = useTranslation();
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      // ...
    } else if (error.status === 404) {
      // ...
    }

    return (
      <Box>
        <Typography>
          {t('oops')} {error.status}
        </Typography>
        <Typography>{error.statusText}</Typography>
        {error.data?.message && <Typography>{error.data.message}</Typography>}
      </Box>
    );
  } else if (error instanceof Error) {
    return (
      <Box>
        <Typography>{t('unexpected_error')}</Typography>
        <Typography>{t('smth_went_wrong')}</Typography>
        <Typography>{error.message}</Typography>
      </Box>
    );
  } else {
    return <></>;
  }
};
