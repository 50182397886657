import { useTranslation } from 'react-i18next';

import { Box, Step, StepButton, Stepper, Typography } from '@mui/material';

import { ReactComponent as CheckCircleOutlineRoundedIcon } from 'assets/check-circle-outline-rounded-icon.svg';

import { VerticalLinearStepperProps } from './types';

const CustomConnector = (
  <Box
    sx={{
      borderColor: '#CDD5DF',
      borderLeftStyle: 'solid',
      borderLeftWidth: '2px',
      minHeight: '24px',
      ml: '12px',
    }}
  />
);

const stepButtonStyles = {
  '& .MuiStepLabel-iconContainer': { pr: '20px' },
  '& .MuiSvgIcon-root': {
    fill: '#FFF',
    outline: '1px solid #CDD5DF',
    borderRadius: '100px',
  },
  '& .MuiStepIcon-text': {
    fill: '#FFF',
  },
};

export const VerticalLinearStepper = ({
  steps,
  activeStep,
  completedSteps,
  onStep,
}: VerticalLinearStepperProps) => {
  const { t } = useTranslation();

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      connector={CustomConnector}
    >
      {steps.map((stepLabel) => (
        <Step
          key={stepLabel}
          completed={completedSteps[stepLabel] ?? false}
          sx={{ display: 'flex', '& .MuiStepLabel-root': { p: '0px' } }}
        >
          <StepButton
            onClick={() => onStep(stepLabel)}
            icon={
              completedSteps[stepLabel] ? (
                <CheckCircleOutlineRoundedIcon />
              ) : undefined
            }
            sx={stepButtonStyles}
          >
            <Typography sx={{ fontWeight: 700 }}>{t(stepLabel)}</Typography>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};
