import { FC, Fragment } from 'react';

import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';

import { StyledChip, StyledFileUploadWrapper } from './styles';
import { FilesPreviewProps } from './types';

export const FilesPreview: FC<FilesPreviewProps> = ({
  files,
  isCurrentUser,
  isChangingMode,
  onDelete,
  onDownload,
}) => (
  <StyledFileUploadWrapper>
    {files.map(({ name, fileId }) => (
      <StyledChip
        key={fileId}
        variant="outlined"
        deleteIcon={
          isCurrentUser && isChangingMode ? <CloseIcon /> : <Fragment />
        }
        onDelete={() => onDelete(fileId)}
        onClick={() => onDownload(fileId)}
        label={name}
      />
    ))}
  </StyledFileUploadWrapper>
);
