import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { StyledDialog, StyledIconButton } from './styles';
import { ConfirmationDialogProps } from './types';

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  open,
  maxWidth = 'xs',
  fullWidth = true,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </DialogTitle>

      <DialogContent>{t(title)}</DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>

        <Button type="submit" onClick={onSubmit}>
          {t('delete')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
