import { Severity } from 'context/types';
import {
  useDeleteAttachmentById,
  useGetAttachmentUrl,
  useUploadAttachments,
} from 'hooks/mutations/attachments/attachments';
import { ChangeEvent, useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { Attachment } from 'models/attachments.model';

import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { ReactComponent as DocumentIcon } from 'assets/document-icon.svg';
import { ReactComponent as ImageIcon } from 'assets/image-icon.svg';
import { ReactComponent as UploadFileIcon } from 'assets/upload-file-icon.svg';

import { documentMimeTypes, imageMimeTypes } from 'constants/index';

import { AppContext } from 'context';

import { FILE_NAME } from './constants';
import {
  StyledChip,
  StyledFileUploadWrapper,
  VisuallyHiddenInput,
} from './styles';
import { InputFileUploadProps } from './types';

const isAttachment = (item: unknown): item is Attachment => {
  if (
    typeof item === 'object' &&
    item !== null &&
    'name' in item &&
    'mimetype' in item &&
    'id' in item &&
    'fileId' in item
  ) {
    const attachment = item as Partial<Attachment>;

    return (
      typeof attachment.name === 'string' &&
      typeof attachment.mimetype === 'string' &&
      typeof attachment.id === 'string' &&
      typeof attachment.fileId === 'string'
    );
  }

  return false;
};

export const InputFileUpload = ({
  element,
  fieldName,
  onUpdateTemplate,
}: InputFileUploadProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });
  const { mutateAsync: getAttachmentUrl } = useGetAttachmentUrl();
  const { mutateAsync: uploadAttachments } = useUploadAttachments();
  const { mutateAsync: deleteAttachmentById } = useDeleteAttachmentById();

  const { multiple, acceptedTypes, styles } = element;

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const formData = new FormData();

      Array.from(files).forEach((file) => formData.append(FILE_NAME, file));

      try {
        const uploadedUrls = await uploadAttachments({ data: formData });

        append(uploadedUrls);
        onUpdateTemplate?.();
      } catch (error) {
        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: t('errors.file.upload_file', { error }),
        });
      }
    }
  };

  const handleDelete = async (id: string, index: number) => {
    try {
      await deleteAttachmentById({ id });

      remove(index);
      onUpdateTemplate?.(element.id);
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.file.delete_file', { error }),
      });
    }
  };

  const handleDownload = async (id: string) => {
    try {
      const downloadUrl = await getAttachmentUrl({ id });
      const link = document.createElement('a');

      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: t('errors.file.get_file', { error }),
      });
    }
  };

  const filteredItems = Array.isArray(fields)
    ? fields.filter(isAttachment)
    : [];

  return (
    <StyledFileUploadWrapper sx={styles}>
      {filteredItems.map(({ name, mimetype, fileId, id }, index) => {
        const imageIcon = imageMimeTypes.includes(mimetype) ? (
          <ImageIcon />
        ) : undefined;

        const documentIcon = documentMimeTypes.includes(mimetype) ? (
          <DocumentIcon />
        ) : undefined;

        return (
          <StyledChip
            key={id}
            variant="outlined"
            deleteIcon={<CloseIcon />}
            onDelete={() => handleDelete(fileId, index)}
            onClick={() => handleDownload(fileId)}
            label={name}
            icon={imageIcon ?? documentIcon}
          />
        );
      })}

      <Button component="label" startIcon={<UploadFileIcon />}>
        {t('attach_document')}

        <VisuallyHiddenInput
          type="file"
          accept={acceptedTypes}
          multiple={multiple}
          onChange={handleFileChange}
        />
      </Button>
    </StyledFileUploadWrapper>
  );
};
