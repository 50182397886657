import { useGetCompositionData } from 'hooks/queries/composition/composition';
import { useGetPotentialFailuresTemplate } from 'hooks/queries/potential-failures-template/potential-failures-template';
import { useGetRiskScoresByProcessId } from 'hooks/queries/risk-score/risk-score';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageLoader } from 'components/page-loader/page-loader';
import { convertToTreeNodes } from 'components/tree-nodes-stepper/utils';

import { TemplateTypes } from 'models/template.model';

import { cloneAndUncollapseNodes } from 'utils';

import { useGetTemplateId } from 'hooks/hooks/useGetTemplateId';

import { PotentialFailuresPanel } from './potential-failures-panel';

const PotentialFailuresPanelWrapper = () => {
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const { processId, organizationId, stepId } = useParams();
  const templateId = useGetTemplateId(
    TemplateTypes.DECOMPOSITION,
    organizationId,
    processId,
    stepId,
  );

  const { data: riskScores, isLoading: isLoadingRiskScores } =
    useGetRiskScoresByProcessId(processId);

  const { data: nodes, isLoading: isLoadingNodes } = useGetCompositionData(
    organizationId,
    processId,
    templateId,
  );

  const { data: templateData, isLoading: isLoadingTemplateData } =
    useGetPotentialFailuresTemplate(
      selectedTemplateId,
      organizationId,
      processId,
    );

  if (isLoadingNodes) {
    return <PageLoader />;
  }

  const clonedNodes = cloneAndUncollapseNodes([...nodes]);
  const treeNodes = convertToTreeNodes(clonedNodes);

  return (
    <PotentialFailuresPanel
      isLoadingTemplateData={isLoadingTemplateData}
      isLoadingRiskScores={isLoadingRiskScores}
      templateData={templateData}
      treeNodes={treeNodes}
      riskScores={riskScores}
      selectedTemplateId={selectedTemplateId}
      onSelectTemplateId={(id: string) => setSelectedTemplateId(id)}
    />
  );
};

export default PotentialFailuresPanelWrapper;
