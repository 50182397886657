export const queryKeys = {
  // admin
  getAdminMessage: 'getAdminMessage',

  // current user
  getCurrentUser: 'getCurrentUser',

  // organization
  getOrganizations: 'getOrganizations',
  getOrganizationById: 'getOrganizationById',

  // request
  getAllRequestsByOrganizationId: 'getAllRequestsByOrganizationId',

  // general information template
  getGeneralInformationTemplate: 'getGeneralInformationTemplate',
  updateGeneralInformationTemplate: 'updateGeneralInformationTemplate',

  // potential failures template
  getPotentialFailuresTemplate: 'getPotentialFailuresTemplate',
  updatePotentialFailuresTemplate: 'updatePotentialFailuresTemplate',

  // composition
  getCompositionData: 'getCompositionData',
  updateCompositionData: 'updateCompositionData',

  // recommended-priority
  getRecommendedPriority: 'getRecommendedPriority',

  // risk-matrix
  getRiskMatrixData: 'getRiskMatrixData',
  updateRiskMatrixData: 'updateRiskMatrixData',

  // attachments
  uploadAttachments: 'uploadAttachments',
  getAttachmentUrl: 'getAttachmentUrl',

  // comments
  getCommentsByUserResponseId: 'getCommentsByUserResponseId',
  createCommentByUserResponseId: 'createCommentByUserResponseId',
  updateCommentById: 'updateCommentById',
  deleteCommentById: 'deleteCommentById',

  // risk scores
  getRiskScoresByProcessId: 'getRiskScoresByProcessId',

  // report
  generateReport: 'generateReport',

  // common
  getToken: 'getToken',
};
