import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

import { StyledCircle } from './types';

const commonStyles = {
  width: 10,
  height: 10,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.common.white}`,
};

export const StyledDoubleCircleWrapper = styled(Box)({
  display: 'flex',
  position: 'relative',
  width: 14,
  height: 10,
});

export const StyledLeftCircle = styled(Box)<StyledCircle>(
  ({ backgroundColor }) => ({
    ...commonStyles,
    backgroundColor: backgroundColor ?? theme.palette.grey.A100,
  }),
);

export const StyledRightCircle = styled(Box)<StyledCircle>(
  ({ backgroundColor }) => ({
    ...commonStyles,
    backgroundColor: backgroundColor ?? theme.palette.grey.A100,
    position: 'absolute',
    left: 6,
  }),
);
