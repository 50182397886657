import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'react-query/queryClient';

import { Notification } from 'components/notification/notification';

import { router } from 'routes';

import { theme } from './configs/theme';
import { devMode } from './constants';

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />

      <Notification />
    </ThemeProvider>

    {devMode && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
);
