import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledOverallRiskWrapper = styled(Box)(({ borderColor }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 4,
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `8px solid ${borderColor}`,
  marginBottom: 12,
}));
